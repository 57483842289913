import {React, useEffect} from 'react';
import classes from './Login.module.css';
import { cognitoURL } from '../../../utilities/globalVariables';

// import { NavLink } from 'react-router-dom';

// const moment = require("moment-timezone");


const Login = (props) => {

    // moment library timezone comparisons and calculations - testing
    // const timeNow = new Date();
    // const bookingDate = "2021-12-06";
    // const startTime = '1625';
    // var momentTzNow = moment.tz(timeNow, "Australia/Sydney");
    // console.log('momentTzNow: ', momentTzNow);
    // console.log('test: ', startTime.substr(2,2));    

    // const hoursFromString = Number.parseInt(startTime.substr(0,2), 10);
    // const minutesFromString = Number.parseInt(startTime.substr(2,2), 10)/60;
    // const calculatedHours = hoursFromString + minutesFromString;
    // console.log('calculatedHours: ', calculatedHours);
    // const startTimeObject = moment.tz(moment(bookingDate, "YYYY-MM-DD").add(calculatedHours, 'hours'), "Australia/Sydney");
    // console.log('startTimeObject: ', startTimeObject);

    // // const timeUntilSession = (startTimeObject - momentTzNow)/1000/3600;
    // const timeUntilSession = startTimeObject.diff(momentTzNow, 'minutes');
    // console.log('timeUntilSession: ', timeUntilSession);


    useEffect(() => {
        // Set the parent component's state value when the child component is mounted
        if (localStorage.getItem('mode') !== null) {
            // If the key exists, update its value
            localStorage.setItem('mode', 'login');
          } else {
            // If the key does not exist, create it
            localStorage.setItem('mode', 'login');
          }
      }, [props]);




    return (
        <div className={classes.Login}>
            {/* <NavLink to="/enrol" className='btn btn-block btn-lg btn-info m-2'>New Enrolment</NavLink> */}
            {/* <NavLink to="/enrol" className='btn btn-block btn-lg btn-light m-2'>Log in</NavLink> */}
            <a className="btn btn-block btn-lg btn-outline-dark m-2" href={cognitoURL}>Register / Login</a>
        </div>
    )
}

export default Login;