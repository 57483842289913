import classes from './WorkLocationsInputs.module.css';
// import { workLocationTypes } from '../../../utilities/globalObjects';
import { humanize, convertDbSavedDateFormat } from '../../../utilities/functions';
import { Fragment, useCallback, useEffect, useState } from 'react';
import Modal from '../../../UI/Modal/Modal';
// import { Fragment } from 'react';
// import SpinnerDark from "../../../UI/SpinnerDark/SpinnerDark";
const days = ['Mon','Tue','Wed','Thu','Fri'];

const WorkLocationsInputs = (props) => {
    const dateShort = props?.dateShort;
    const managingResources = props?.managingResources;
    const workLocationTypes = props?.workLocationTypes;
    const submitWorkLocations = props?.submitWorkLocations;
    const years = props?.years;
    const managingResourceWorkLocations = props?.managingResourceWorkLocations;

    console.log("Managing Resources: ", managingResources)
    const [showCommentModal, setShowCommentModal] = useState(false);
    const [modalResourceId, setModalResourceId] = useState();
    const [workLocations, setWorkLocations] = useState([]);

    const initialiseWorkLocations = useCallback(() => {
        let defaultLocations = [];
        setWorkLocations(managingResources.map((resource) => {
            // for each day of the week
            for (let i = 0; i<5; i++){
                const parts1 = `${dateShort[i]}/${years[i]}`.split('/');
                const formattedDate1 = `${parts1[2]}-${parts1[1].padStart(2, '0')}-${parts1[0].padStart(2, '0')}`;
                console.log("Resource ID: ", resource?.resourceId);
                let workLocationRow = managingResourceWorkLocations.find(obj => obj?.resource_id === resource.resourceId && convertDbSavedDateFormat(obj?.date) === formattedDate1);
                if(!workLocationRow){
                    //We look for the last entered work location for that day of the week
                    const targetDate = new Date(formattedDate1);
                    workLocationRow = managingResourceWorkLocations
                        .filter(obj => new Date(convertDbSavedDateFormat(obj.date)).getDay() === targetDate.getDay() && new Date(convertDbSavedDateFormat(obj.date)) < targetDate && obj.resource_id === resource.resourceId)
                        .sort((a,b) => new Date(convertDbSavedDateFormat(b.date)) - new Date(convertDbSavedDateFormat(a.date)))
                        .shift();
                }
                if (workLocationRow){
                    defaultLocations?.push({
                        locationId: workLocationRow?.location_id,
                        resourceId: resource?.resourceId,
                        date: `${dateShort[i]}/${years[i]}`,
                        comment: workLocationRow?.comment
                    });
                }
                else{
                    defaultLocations?.push({
                        locationId: 1,
                        resourceId: resource?.resourceId,
                        date: `${dateShort[i]}/${years[i]}`
                    });
                }
            }
    
            return defaultLocations;
        }).flat(Infinity))
    }, [dateShort, managingResourceWorkLocations, managingResources, years]);

    useEffect(() => {
        initialiseWorkLocations();
    }, [initialiseWorkLocations]);

    const handleSave = () => {
        submitWorkLocations(workLocations);
    }

    const handleWorkLocationChange = (locationId, resourceId, date) => {
        if (workLocations?.some(obj => obj?.resourceId === resourceId && obj?.date === `${date}/${years[0]}`)){
            // object already exists
            const newWorkLocations = workLocations?.map(obj => {
                if (obj?.resourceId === resourceId && obj?.date === `${date}/${years[0]}`){
                    return {...obj, locationId: locationId};
                }
                return obj;
            });
            setWorkLocations(newWorkLocations);
        } else {
            // new object
            setWorkLocations([...workLocations, {
                locationId: locationId,
                resourceId: resourceId,
                date: `${date}/${years[0]}` 
            }]);
        }
    };

    const handleCommentModal = (resourceId) => {
        setModalResourceId(resourceId);
        setShowCommentModal(true);
    }

    let contents;
    if (managingResources && managingResources?.length > 0 && workLocationTypes?.length > 0){
        contents = managingResources?.map((resource, row) => {
            const resourceId = resource?.resourceId;
            const email = resource?.email;
            const name = email?.split('@')[0];
            const firstName = name?.split('.')[0];
            const lastName = name?.split('.')[1];
            const fullName = humanize(firstName) + humanize(lastName);

            // Show resource name in left column
            let rowHeader = (
                <Fragment>
                    <td>
                        <span>{fullName}</span>
                    </td>   
                </Fragment>
            )
            
            // create work location options for drop down
            let workLocationOptions = workLocationTypes.map((workLocationType) => {
                let selectState = '';
                return <option key={workLocationType?.location_id} value={workLocationType?.location_id} select={selectState}>{workLocationType?.location_type}</option>
            });

            // create row for resource to enter work locations
            let rowData = [];
            for (let i = 0; i < 5; i++){
                rowData.push(
                    <Fragment>
                        <td>
                            <select value={workLocations?.find(obj => obj?.resourceId === resource?.resourceId && obj?.date === `${dateShort[i]}/${years[i]}`)?.locationId} onChange={(event) => handleWorkLocationChange(+event.target.value, resourceId, dateShort[i])}>
                                {workLocationOptions}
                            </select>
                        </td>
                    </Fragment>
                );
            }
            rowData.push(<td><button className={['btn btn-secondary', classes.CommentButton].join(' ')} onClick={() => handleCommentModal(resourceId)}><span className="glyphicon glyphicon-comment" ></span></button></td>)

            return (
                <Fragment>
                    <tr>   
                        {rowHeader}
                        {rowData}
                    </tr>
                </Fragment>
            );
        })
        

    }

    const handleCommentChange = (index, inputText) => {
        const updatedWorkLocations = workLocations?.map(workLocation => {

            if (workLocation.date === `${dateShort[index]}/${years[index]}` && workLocation?.resourceId === modalResourceId){
                return { ...workLocation, comment: inputText}
            }
            return workLocation;

        })
        setWorkLocations(updatedWorkLocations);
    }

    let commentContent = [<h3>Comments</h3>]
    if (showCommentModal && modalResourceId){
        for (let i=0; i<5; i++){
            commentContent.push(
                <div>
                    <br/>
                    <p>{days[i]}</p>
                    <textarea 
                    // key={modalResourceId}
                    type='text' 
                    className={['form-control', classes.CommentInputText].join(' ')}
                    value={workLocations?.find(obj => obj?.date === `${dateShort[i]}/${years[i]}` && obj?.resourceId === modalResourceId)?.comment}
                    cols='2'
                    rows='3'
                    onChange={(e) => handleCommentChange(i, e.target.value)}
                    />
                </div>
            );
        }
    }

    return (
        <div className={classes.WorkLocationsInputs}>
            <Fragment>
                <Modal 
                    large tall
                    show={showCommentModal}
                    modalClosed={()=>setShowCommentModal(false)}>
                    <div className={classes.CommentModal}>
                        {commentContent}
                    </div>
                </Modal>
            </Fragment>
            <table className={["table table-striped table-hover table-bordered", classes.Table].join(' ')}>
                <thead>
                    <tr>
                        <th className={classes.SelectTH}>Resource</th>
                        <th className={classes.InputTH}>{`Mon ${dateShort[0]}`}</th>
                        <th className={classes.InputTH}>{`Tue ${dateShort[1]}`}</th>
                        <th className={classes.InputTH}>{`Wed ${dateShort[2]}`}</th>
                        <th className={classes.InputTH}>{`Thur ${dateShort[3]}`}</th>
                        <th className={classes.InputTH}>{`Fri ${dateShort[4]}`}</th>
                        <th className={classes.InputTH}>Comment</th>
                    </tr>
                </thead>
                <tbody>
                    {contents}
                </tbody>
            </table>
                <hr/>
            <div className={classes.HeaderButtonRow}>
                <div className={classes.ChildButton}>
                    <button className="btn btn-block btn-success" onClick={handleSave}>Submit</button>
                </div>          
            </div>        
        </div>
    );
}

export default WorkLocationsInputs;