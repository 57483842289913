import { Fragment, React, useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";

import classes from "./MasterData.module.css";
import axios from "axios";
import SpinnerDark from "../../../UI/SpinnerDark/SpinnerDark";
import Modal from "../../../UI/Modal/Modal";
import Banner from "../../../UI/Banner/Banner";
import BSTable from "../../../UI/BSTable/BSTable";
import ParamsForm from '../../../UI/ParamsForm/ParamsForm';
import BackdropSpinner from '../../../UI/BackdropSpinner/BackdropSpinner';
// import MasterDataTable from "../../../UI/MasterDataTable/MasterDataTable";
import Aux from "../../../hoc/Auxiliary/Auxiliary";

import { raw } from '../../../translations/en/raw';

import {
  AWS_API,
  cognitoClientId,
  cognitoUserpoolId,
} from "../../../utilities/globalVariables";
// import { masterDataPrimaryKeys } from '../../../utilities/globalObjects';
import { handleAwsApiError,
  //  handleBanner 
  handleBannerText
  } from "../../../utilities/functions";

import { bootstrapTableConfig, masterDataButtons } from '../../../utilities/globalObjects';

const MasterData = (props) => {
  // data state
  const [data, setData] = useState();
  const [selectOptionsData, setSelectOptionsData] = useState();
  const [selectedTable, setSelectedTable] = useState();
  const [newEntryData, setNewEntryData] = useState({});

  // UI state
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCellUpdate, setLoadingCellUpdate] = useState(false);
  const [loadingAddEntry, setLoadingAddEntry] = useState(false);
  // const [showBanner, setShowBanner] = useState(false);
  // const [bannerClass, setBannerClass] = useState();
  const [bannerText, setBannerText] = useState("");
  const [showInsertRowModal, setShowInsertRowModal] = useState();

  // Bootstrap table state
  const [selected, setSelected] = useState([]);

  // react router history instance to navigate user to other pages
  let history = useHistory();
  const location = useLocation();

  console.log("Data: ", data);
  // configuration options needed throughout component
  let insertRowConfig = bootstrapTableConfig[selectedTable]?.insertRow;

  const mode_debug = false;

  const getMasterData = async (tableName) => {
    setLoading(true);

    // clear any previously applied filters now we're fetching a new table
    // setColumnFilters({});
    

    setSelectedTable(tableName);
    

    // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
    const config = {
      headers: {
        authorization: props.token,
        appclientid: cognitoClientId,
        userpoolid: cognitoUserpoolId,
      },
      params: { table: bootstrapTableConfig[tableName]?.view ? bootstrapTableConfig[tableName]?.view : tableName },
    };
    console.log("Config: ", config)

    // call lambda function to get master data
    try {
      const res = await axios.get(AWS_API + "master-data/", config);
      setData(res.data);
    } catch (err) {
      setError(
        handleAwsApiError(err, history) ??
          "Error encountered while fetching master data"
      );
    }
    setLoading(false);
  };



  const getSelectOptions = useCallback(async (optionsArray) => {
    setLoadingAddEntry(true);
    
    // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
    const config = {
      headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId},
      params: {}
    };

    // loop through each table and collect data for select options
    let newSelectOptionsData = {};
    for (const options of optionsArray){
      
      // pull values out of options config object
      const table = options?.name;
      const value = options?.value;
      const labels = options?.labels;
      const condition = options?.conditions?.[0];

      // set config params to pass to lambda
      config.params.table = table;
      if (condition) {
        config.params.conditionCol = condition.col;
        config.params.conditionValue = condition.value;
      }

      // call lambda function to get master data
      try{
        const res = await axios.get(AWS_API + 'master-data/fetch', config);
        const data = res.data;

        // map data into array of objects with value and label
        const options = data.map(obj => {
          const label = labels.map(label => obj[label]).join(' ');
          return ({value: obj[value], label: label})
        })

        newSelectOptionsData[table] = options;
      } catch (err) {
        setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching master data');
      }
    }

    setSelectOptionsData(newSelectOptionsData);

    setLoadingAddEntry(false);

  }, [history, location, props.token])

  // any time insert entry modal is showing, check insertEntry config, get any necessary select options
  useEffect(() => {
    // only run if showInsertRowModal is true
    if (!showInsertRowModal){
      return false;
    }

    // determine if select options need fetching
    const selectOptionsConfig = insertRowConfig.filter(obj => obj?.options?.source === 'database')?.map(obj => obj?.options);
    console.log('selectOptionsConfig: ', selectOptionsConfig);

    if (selectOptionsConfig?.length > 0){
      getSelectOptions(selectOptionsConfig)
    }

  }, [showInsertRowModal, getSelectOptions, insertRowConfig])
  
  const updateData = async (oldValue, newValue, row, column) => {
    setLoadingCellUpdate(true);

    // get primary keys
    const primaryKeys = bootstrapTableConfig[selectedTable]?.primaryKeys;
    if (!primaryKeys){
      setError('Primary keys not found for selected table: ' + selectedTable);
      setLoadingCellUpdate(false);
      return false;
    }

    // construct primary key object
    const primaryKeysObj = {};
    primaryKeys.forEach(key => primaryKeysObj[key] = row[key])

    const headers = {headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}};

    const updateCol = column.dataField;
    const postData = {
      table: selectedTable,
      updateObj: {[updateCol]: newValue},
      primaryKeysObj: primaryKeysObj
    };
    console.log('posting: ', postData);

    //call lambda function

    try {
      if (mode_debug){
        setLoadingCellUpdate(false);
        return false;
      }
      const res = await axios.post(AWS_API + 'master-data/update-data', {postData: postData}, headers);
      console.log('res: ', res.data);
      handleBannerText(setBannerText, `Data Updated Successfully`);
    } catch (err) {
      setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while updating settings');
      console.log('error whilst recording roll event: ', err);
    }

    setLoadingCellUpdate(false);
  }


  const insertRow = async (table, row) => {
    
    //close modal
    setLoadingAddEntry(true);

    const headers = {
      headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId},
    };
    const postData = {
      table: table,
      rows: [row]
    }

    try {

      if (mode_debug){
        console.log('Entered')
        getMasterData(selectedTable);
        handleBannerText(setBannerText, 'Entry Added Successfully')
        setNewEntryData();
        setShowInsertRowModal(false);
        setLoadingAddEntry(false);
        return false;
      }
      console.log('Entered');
      const res = await axios.post(AWS_API + 'master-data/insert', {postData: postData}, headers);
      console.log('response: ', res.data);
      getMasterData(selectedTable);
      handleBannerText(setBannerText, 'Entry Added Successfully')
      setNewEntryData();
      
  } catch (err) {
      setError(handleAwsApiError(err, history, location) ?? 'Unexpected error while updating user groups');
  }     
  
  setShowInsertRowModal(false);
  setLoadingAddEntry(false);
  }



  // if showInsertRowModal is true, populate form
  let insertRowForm = <SpinnerDark/>
  if (showInsertRowModal && !loadingAddEntry && insertRowConfig){

    let paramsFormConfig = [];
    for (const row of insertRowConfig){
      const dataType = row?.dataType;
      const columns = row?.columns;
      const selectOptionsConfig = row?.options;
      const selectOptionsSource = selectOptionsConfig?.source;
      const selectOptionsName = selectOptionsConfig?.name;
      const unique = selectOptionsConfig?.unique;
      const sort = selectOptionsConfig?.sort;


      console.log('selectOptionsConfig: ', selectOptionsConfig);
      console.log('selectOptionsName: ', selectOptionsName);

      // get select options depending on source
      let selectOptions;
      if (selectOptionsSource === 'database'){

        // if any inputs need database select options, make sure we have these before going any further
        if (!selectOptionsData) return false;
        
        selectOptions = selectOptionsData?.[selectOptionsName];
      } else if (selectOptionsSource === 'hardcoded'){
        selectOptions = selectOptionsConfig?.list;
      } else if (selectOptionsSource === 'globalObjectsArray'){
        const array = selectOptionsConfig?.array;
        console.log('array: ', array)
        selectOptions = array.map(el => ({value: el, label: raw[el] ?? el}))
      }

      for (const col of columns){
        paramsFormConfig.push({name: col, type: dataType, options: selectOptions, valueProperty: 'value', unique: unique, sort: sort});
      }
    }
    if (bootstrapTableConfig[selectedTable]?.addEmail){
      // const emails = getSelectOptions({source: 'database', name: 'resources', value: 'resource_id', labels: ['email'], sort: true})
      // console.log("Emails", emails)
      // const emailRow = {columns: [bootstrapTableConfig[selectedTable]?.addEmail?.column],
      //                   dataType: 'react-select',
      //                   options: {source: 'hardcoded', list: [{value: props.email, label: props.email}]},
      //                   }
      // const emailOptions = emailRow?.options?.list;
      // console.log("Email Row", emailRow);
      // console.log("Column Name: ", emailRow.columns[0][0]);
      // paramsFormConfig.push({name: emailRow.columns[0][0], type: emailRow.dataType, options: emailOptions});
      // paramsFormConfig.push({name: bootstrapTableConfig[selectedTable]?.addEmail?.column, type: })
      if (!newEntryData.hasOwnProperty(bootstrapTableConfig[selectedTable]?.addEmail?.column)){
        setNewEntryData({...newEntryData, [bootstrapTableConfig[selectedTable]?.addEmail?.column] : props.email});
      }
    }
    console.log('paramsFormConfig: ', paramsFormConfig);
    insertRowForm = (
      <Fragment>
        <ParamsForm 
          data={newEntryData}
          setData={setNewEntryData}
          config={paramsFormConfig}
        />
        <button className='btn btn-block btn-success' onClick={() => insertRow(selectedTable, newEntryData)}>Submit</button>
        <br/>
      </Fragment>
    );
    console.log('New Entry Data: ', newEntryData);
  }

  // Insert Bootstrap Table by using the BSTable component found in the UI folder
  // let table = <MasterDataTable/>
  let table = (
      <BSTable
      selected={selected}
      setSelected={setSelected}
      table={selectedTable}
      data={data}
      enableEdit
      updateFunction={updateData}
      />
    )
  console.log(data);
  // if (data) {
  //   table = (
  //     <BSTable
  //     />
  //   )
  // }

  // let table = (
  //   <MasterDataTable
  //     insertAllowed
  //     handleBanner={(text) =>
  //       handleBanner(setBannerText, text, "alert alert-success", setBannerClass)
  //     }
  //     updateAllowed
  //     deleteAllowed="delete"
  //     token={props.token}
  //     updateCompleted={getMasterData}
  //     setLoading={setLoading}
  //     setError={setError}
  //     data={data}
  //     table={selectedTable}
  //   />
  // );
  // if (data) {
  // table = <MasterDataTable submit={updateMasterData} data={data}/>;

  // table = <MasterDataTable showFilter={showFilter} updateFilterValue={updateFilterValue} columnToFilter={columnToFilter} filters={columnFilterOptions} headerClicked={openFilterPane} clicked={clicked} changed={setUpdateValue} submit={updateMasterData} updateRow={updateRow} updateCol={updateCol} updateValue={updateValue} data={filteredData}/>;
  // }
  // console.log('filtered data: ', filteredData);

  let masterDataContent = <SpinnerDark />;
  if (!loading) {
    masterDataContent = <Aux>{table}</Aux>;
  }

  let backdropSpinner;
  if (loadingCellUpdate) {
    backdropSpinner = <BackdropSpinner spinner='small-light'/>;
  }

  const getButtons = (array) => {
    let buttons = [];
    for (const button of array){
      buttons.push(<button
      key={button.table} className={[
        classes.Button,
        "btn",
        "btn-sm",
        "btn-outline-success",
        "px-3",
      ].join(" ")}
      onClick={() => getMasterData(button.table)}
      >
      {button.label}
      </button>);
    }
    return buttons;
  }

  return (
    <div className={classes.MasterData}>
      <Modal show={error} modalClosed={() => setError(false)}>
        <h3>Oops, something went wrong!</h3>
        <hr />
        <p>{error}</p>
      </Modal>
      <Modal large tall show={showInsertRowModal} modalClosed={() => setShowInsertRowModal(false)}>
          <h3>Add Entry to {selectedTable}</h3>
          <hr/>
          <div>{insertRowForm}</div>
      </Modal>
      <Banner show={bannerText} >
        {bannerText}
      </Banner>
      {backdropSpinner}
      <div className={classes.HeaderButtonRowRight}>
        {insertRowConfig && <button className='btn btn-success btn-sm' onClick={() => setShowInsertRowModal(true)}>Add Entry</button>}
      </div>
      {/* onClick={updateRow ? clearUpdateValues : null} */}
      {getButtons(masterDataButtons)}

      {masterDataContent}
    </div>
  );
};

export default MasterData;
