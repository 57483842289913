import React from 'react';
import classes from './Backdrop.module.css';

const backdrop = (props) => {
    
    const classList = [classes.Backdrop];
    
    if (props.showOnTop) {
        classList.push(classes.ShowOnTop)
    }
    
    const content = props.show ? <div className={classList.join(' ')} onClick={props.clicked}></div> : null
    
    
    return content;
}

export default backdrop;