import './App.css';

import { React, useState, useEffect, useRef } from 'react';
import {
  // BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";

import 'url-search-params-polyfill';
// import axios from 'axios';

import Layout from './hoc/Layout/Layout';
import Auth from './Components/Auth/Auth';
import AuthDashboard from './Components/Auth/AuthDashboard';
import Login from './Components/Auth/Login/Login';
import LoginDashboard from './Components/Auth/Login/LoginDashboard';
import TimeSheet from './Components/TimeSheet/TimeSheet';
import MasterData from './Components/Admin/MasterData/MasterData';
import ResourceAssign from './Components/TimeSheet/ResourceAssign/ResourceAssign';
import Reporting from './Components/TimeSheet/Reporting/Reporting'
import Links from './Components/Links/Links';
import Requests from './Components/TimeSheet/Requests/Requests';
import WorkLocations from './Components/WorkLocations/WorkLocations';
import WorkLocationsDashboard from './Components/WorkLocations/WorkLocationDashboard';

// import Spinner from './UI/Spinner/Spinner';


function App() {

  // hooks for dealing with URLs 
  const history = useHistory();
  const location = useLocation();

  const [email, setEmail] = useState(); 
  const [role, setRole] = useState(); 

  // token strategy: pass through props to components that definitely need it to make API calls, use localStorage for components like
  // Auth.js which may be redirected to after an API call fails or a refresh (i.e. localRefreshToken will come from localstorage)
  const [token, setToken] = useState(); 

  // PWA state
  const [showInstallPrompt, setShowInstallPrompt] = useState();
  const [showInstallInstruct, setShowInstallInstruct] = useState();
  const [currentPath, setCurrentPath] = useState(location.pathname);
  console.log("Current Path: ", currentPath);
  //---------------------------------------------------------------------------------------------------------------------------------
  // PWA SETUP
  //---------------------------------------------------------------------------------------------------------------------------------

    let installPrompt = useRef();

    useEffect(() => {
        console.log("Listening for Install prompt");
        window.addEventListener('beforeinstallprompt',e => {
          // For older browsers
          e.preventDefault();
          console.log("Install Prompt fired");
          installPrompt.current = e;
          // See if the app is already installed, in that case, do nothing
          if((window.matchMedia && window.matchMedia('(display-mode: standalone)').matches) || window.navigator.standalone === true){
            return false;
          }
          // Set the state variable to make button visible
          setShowInstallPrompt(true);
        });
    
        // Checks if should display install instructions in sidebar
        if (isIos() && !isInStandaloneMode()) {
          setShowInstallInstruct(true);
        }

    }, []);


    // Detects if device is on iOS 
    const isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test( userAgent );
    }

    // Detects if device is in standalone mode
    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);


    const installApp = async () => {
        if(!installPrompt.current) return false;
            installPrompt.current.prompt();
            let outcome = await installPrompt.current.userChoice;
        if(outcome.outcome === 'accepted'){
            console.log("App Installed")
        }
        else{
            console.log("App not installed");
        }
        // Remove the event reference
        installPrompt.current = null;
        // Hide the button
        setShowInstallPrompt(false);
    }
    

  //---------------------------------------------------------------------------------------------------------------------------------
  //---------------------------------------------------------------------------------------------------------------------------------



  // signout function
  const signout = () => {
    history.replace("/login");
    setEmail();
    setRole();
    localStorage.removeItem('jwt-token');
    localStorage.removeItem('jwt-token-refresh');
  }

  // check if we've been redirected here from Cognito with a grant code, and if not check if user has a token in local storage or a refresh token to auth with rather than making them sign into Cognito again
  let cognitoGrantCode = useRef();
  let localRefreshToken = useRef();
  let localToken = useRef();
  useEffect(() => {
    const parsedURL = new URLSearchParams(location.search);
    cognitoGrantCode.current = cognitoGrantCode.current ?? parsedURL.get("code");
    if (cognitoGrantCode.current && parsedURL.get("code")) {
  
      // code found, so redirect to auth with code to authenticate user
      // setCode(cognitoGrantCode);
      console.log('code found: ', cognitoGrantCode);
      console.log('calling history/replace("auth")');
      // setRole('beingAuthenticated');
      history.replace("/auth");
    } else {
        localRefreshToken.current = localStorage.getItem("jwt-token-refresh");
        localToken.current = localStorage.getItem("jwt-token");

        // if there's no grant code and there is a token or refresh token, force redirect to Auth where Auth component will see there's no code in props, but there is a refresh token in local storage
        if (localRefreshToken.current || localToken.current) {
            console.log('[App.js] - local token or refresh token found, redirecting to auth with said token');
            history.replace('/auth');
        } else {
            console.log('[App.js] - no code found and no tokens found, continuing with component render')
        }
  
    }
  }, [location.search, history])

    // set group based on cognito user group
    const updateAuthState = (email, groups, token) => {
      setToken(token);
      setEmail(email);
      let redirectPath;
      if (!['/', '/login', '/badToken'].includes(currentPath)) {
          redirectPath = currentPath;
          console.log(`[App.js Auth] - remembering user's route, redirect path set to ${currentPath}`);
      } else {
          console.log(`[App.js Auth] - currentpath = ${currentPath}, so leaving app to auto redirect to default route if nothing changes`);
          
      }



      if (groups.includes("dashboard")) {
        redirectPath ? history.replace(redirectPath) : history.replace('/work-locations');
        if (!redirectPath) setCurrentPath('/work-locations');
        setRole('Dashboard');
        console.log('set role to dashboard and redirected to /work-locations');
      }
      else if (groups.includes("superUser")) {
        redirectPath ? history.replace(redirectPath) : history.replace('/timesheet');
        if (!redirectPath) setCurrentPath('/timesheet');
        setRole('SuperUser');
        console.log('set role to superUser and redirected to /timesheet');
      } else if (groups.includes("user")) {
        redirectPath ? history.replace(redirectPath) : history.replace('/timesheet');
        if (!redirectPath) setCurrentPath('/timesheet');
        history.replace('/timesheet');
        setRole('User');
        console.log('set role to user and redirected to /timesheet');
      } else {
        history.replace('/');
        setRole('Guest');
      }
    }   

    let routes = null;
    console.log('role: ', role);
    const mode = localStorage.getItem('mode');
    const dashboardMode = mode==='dashboard' ? true : false;
    console.log(mode);


    switch (role) {
      case 'Guest':
        routes = (
          <Switch>
          </Switch>     
        );
        break;
      case 'User':
        routes = (
            <Switch>
                <Route path='/timesheet' render ={(props) => <TimeSheet {...props} email={email} token={token}/> }/>
                <Route path="/auth" render={(props) => <Auth {...props} updateAuthState={updateAuthState} code={cognitoGrantCode.current}/>} />
                <Route path='/links' render={(props) => <Links {...props} email={email} token={token} role={role}/>}/>
                <Route path='/requests' render={(props) => <Requests {...props} email={email} token={token} role={role}/>}/>
                <Route path='/work-locations' render={(props) => <WorkLocations {...props} email={email} token={token} />}/>
                <Redirect to="/timesheet" />
          </Switch>  
        );
        break;
      case 'Manager':
          routes = (
              <Switch>
                  <Route path='/timesheet' render ={(props) => <TimeSheet {...props} email={email} token={token}/> }/>
                  <Route path="/auth" render={(props) => <Auth {...props} updateAuthState={updateAuthState} code={cognitoGrantCode.current}/>} />
                  <Route path='/links' render={(props) => <Links {...props} email={email} token={token} role={role}/>}/>
                  <Route path='/requests' render={(props) => <Requests {...props} email={email} token={token} role={role}/>}/>
                  <Route path='/work-locations' render={(props) => <WorkLocations {...props} email={email} token={token} />}/>
                  <Redirect to="/timesheet" />
            </Switch>  
          );
          break;
      case 'SuperUser':
        routes = (
            <Switch>
                <Route path='/masterdata' render ={(props) => <MasterData {...props} email={email} token={token}/> }/>
                <Route path='/assign-resource' render ={(props) => <ResourceAssign {...props} email={email} token={token}/> }/>
                <Route path='/timesheet' render ={(props) => <TimeSheet {...props} email={email} token={token}/> }/>
                <Route path="/auth" render={(props) => <Auth {...props} updateAuthState={updateAuthState} code={cognitoGrantCode.current}/>} />
                <Route path='/reporting' render={(props) => <Reporting {...props} email={email} token={token}/>}/>
                <Route path='/links' render={(props) => <Links {...props} email={email} token={token} role={role}/>}/>
                <Route path='/requests' render={(props) => <Requests {...props} email={email} token={token} role={role}/>}/>
                <Route path='/work-locations' render={(props) => <WorkLocations {...props} email={email} token={token} />}/>
                <Redirect to="/timesheet" />
          </Switch>  
        );
        break;
        case 'Dashboard':
          routes = (
              <Switch>
                  <Route path='/work-locations' render={(props) => <WorkLocationsDashboard {...props} email={email} token={token} />}/>
                  <Route path="/auth" render={(props) => <AuthDashboard {...props} updateAuthState={updateAuthState} code={cognitoGrantCode.current}/>} />
                  <Redirect to="/work-locations" />
            </Switch>  
          );
          break;
        default:
            routes = (
                <Switch>
                    <Route path="/login" render={(props) => <Login {...props} />} />
                    <Route path="/dashboard" render={(props) => <LoginDashboard {...props} />} />
                    {/* <Route path="/auth" render={(props) => <AuthDashboard {...props} updateAuthState={updateAuthState} code={cognitoGrantCode.current}/>} /> */}
                    {/* <Route path="/auth" render={(props) => <Auth {...props} updateAuthState={updateAuthState} code={cognitoGrantCode.current}/>} /> */}
                    <Route 
                      path="/auth" 
                      render={(props) => dashboardMode ? 
                        <AuthDashboard {...props} updateAuthState={updateAuthState} code={cognitoGrantCode.current}/> : 
                        <Auth {...props} updateAuthState={updateAuthState} code={cognitoGrantCode.current}/>
                      }
                    />
                    <Redirect to="/login" />
                </Switch>
        );
      
    }    

      return (

        // <Suspense fallback={<Spinner />}>
          
          <div className="App">
            {/* <Router> */}
              <Layout 
                email={email} 
                role={role} 
                signout={signout}
                showInstallPrompt={showInstallPrompt} 
                showInstallInstruct={showInstallInstruct}
                accepted={installApp} 
                dismissed={() => setShowInstallPrompt(false)}
              >
    
                {routes}
              </Layout>
              {/* <TimeSheet email= {email}/> */}
    
            {/* </Router> */}
          </div>
    
        // </Suspense>
      );
    
  }


  // function to deal with S3 routing issues - S3 will redirect URL paths to /#/pathname, and this function will set route accordingly
  // const replaceHashPath = () => {
  //   const hash = location.hash;

  //   // console.log('location: ', location);
  //   // console.log('hash: ', hash);
  //   if (hash) {
  //       console.log('checking if need to replace path: ', hash);
  //       const path = hash.replace(/^#/, '')
  //       if (path) {
  //         console.log('replacing path with: ', path);
  //         setTimeout(() => history.replace(path), 10);
  //         // history.replace(path);
  //       }
  //     }
  //   }
  //   replaceHashPath();
    // console.log('location: ', location);

  

export default App;
