import { Fragment, useEffect, useState } from "react";
import classes from "./DailyTSView.module.css";
import { statusMap } from '../../../utilities/globalObjects'; 
// import { useSwipeable } from "react-swipeable";
// const days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
// var month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul","Aug", "Sep", "Oct", "Nov", "Dec"];

const DailyTSView = (props) => {
  console.log("RES DATA", props.data)
  console.log("PROPSDate", props.date)
  //Data state
  const [entries, setEntries] = useState();
  // const [projects, setProjects] = useState();
  // const [bookings, setBookings] = useState(props.data);

  //pull values of out props
  let projects;
  let activities;
  if (props.data) {
      projects = props.data.projects;
      activities = props.data.activities;
  }
  //config settings for swipeable
  // const config = {
  //   delta: 10, // min distance(px) before a swipe starts. *See Notes*
  //   preventDefaultTouchmoveEvent: false, // call e.preventDefault *See Details*
  //   trackTouch: true, // track touch input
  //   trackMouse: false, // track mouse input
  //   rotationAngle: 0, // set a rotation angle
  // };

  // const handlers = useSwipeable({
  //   onSwipedLeft: () => {
  //     let newDate = new Date(props.date);
  //     newDate.setDate(newDate.getDate() + 1);
  //     props.setDate(newDate);
  //   },
  //   onSwipedRight: () => {
  //     let newDate = new Date(props.date);
  //     newDate.setDate(newDate.getDate() - 1);
  //     props.setDate(newDate);
  //   },
  //   ...config,
  // });

  useEffect(() => {
    if (props.data) {
      const bookings = props.data.bookings;
      const filteredBookings = bookings.filter((obj) => {
        const objectDate = new Date(obj.date).toDateString();
        const propDate = new Date(props.date).toDateString();
        return objectDate === propDate;
      });
      const newEntries = {};
      filteredBookings.forEach((obj, i) => {
        newEntries[i] = { ...obj };
        if (!newEntries[i].actual_hours){
          newEntries[i].actual_hours = newEntries[i].forecast_hours;
          newEntries[i].forecasted = true;
      }

      });
      if (newEntries) {
      }
      // if (props.date){
      //   console.log("PROPDS",props.date)
      //   // console.log('PROPSDATE',props.date.toLocaleDateString().trim(0,10))
      //   setDateSelectState(props.date.toLocaleDateString().trim(0,10))
      // }
      setEntries(newEntries);
    }
  }, [setEntries, props.data, props.date]);

  const onInputChange = (key, property, value) => {
    //update state without mutating
    console.log("key: ", key, " property ", property, " value: ", value);
    //spread operator that does deep copies
    //spreads it into object then goes deeper
    const forecasted = (!property === 'actual_hours')
    let newEntries = {
      ...entries,
      [key]: {
        ...entries[key],
        [property]: value,
        forecasted: forecasted //test
      },
    };
    // if project id is changed set activity id to ''
    if (property === 'project_id'){
      newEntries = {
        ...newEntries,
        [key]: {
          ...newEntries[key],
          activity_id: ''
        }
      }
    }
    setEntries(newEntries);
  };


  const removeEntry = (key) => {

    let entryIndex = Object.keys(entries);
    console.log(key);
    const newEntries = {};
    //create deep copy
    for (const i in entryIndex){
      newEntries[i] = {...entries[i]}
    }
    console.log("beforeDelete", newEntries)
    delete newEntries[key];
    console.log("afterDelete", newEntries)
    setEntries(newEntries)

  }


 const submitData =  (entries) => {
    //change status to submitted before sending back to DB
    let newData = {};
    const keys = Object.keys(entries);
    console.log(keys[0])
    console.log("Subtmie Data keys", keys);
    console.log("curren Entries", entries)

    for (const key in keys){
      newData[key] = {...entries[key], 'status': statusMap['Submitted']}
      
    }
    // for (let i = 0; i < keys.length; i++){
    //   newData[keys[i]] = {...entries[keys[i]], 'status': 'submitted'}
      
    // }
    console.log("Aftersubmite",newData)
    props.updateData(newData)
 }
 //what to do when user clicks submit, update object and set status if not yet saved or submitted by user
 const saveData = (entries) => {

    let newData = {};
    const keys = Object.keys(entries);
    for (const key in keys){
      newData[key] = {...entries[key]}
        newData[key].status = statusMap['Open']
    }
    props.updateData(newData)
 }

  const addNewRow = () => {

    //go into entries and add row with properties
    let length = Object.entries(entries).length;
    let newObj = { ...entries };
    const currentDay = new Date(props.date).getDay();
    const currentMonth = new Date(props.date).getMonth();
    const currentYear = new Date(props.date).getFullYear();
    console.log("date individual", currentDay, currentMonth, currentYear);
    console.log("propsDATETest", props.date)
    if (Number.isInteger(length)) {
      newObj[length] = {
        date: props.date,
        resource_id: props.data.resourceId[0].resource_id,
        activity_id: '',
        project_id: ''
        // actual_hours: 0.00
      };

    }
    console.log("New Entrie Object", newObj)
    // //add emtpy comment to activity comment
    // newObj[length].actual_comments = '';
    setEntries(newObj);

  };

  console.log(entries)

  let mappings;
  // get content ready for use input
  if (entries && Object.entries(entries).length > 0) {
    // console.log("projects prop", projects);
    let projectOptions = [];
    if (projects) {
      projectOptions = projects.map((obj) => {
        return <option key={obj.project_id} value={obj.project_id} className={classes.OptionStyle}>{obj.project_desc}</option>;
      });
      projectOptions.unshift(
        <option value="Project" key="default" selected hidden>Select Project</option>
      );
    }
    mappings = Object.keys(entries).map((key) => {
      const projectId = entries[key].project_id;
      let disableStatus = false;
      console.log("typeof", typeof(projectId))
      let activityOptions = [...activities]
        .filter((obj) => +obj.project_id === +projectId)
        .map((obj) => {
          console.log('obj console', obj)
          let selectState ='';
            if (+obj.activity_id === +entries[key].activity_id){
                console.log("TRUUEEEE")
                selectState = 'true';
            }
          return <option key={obj.activity_id} value={obj.activity_id} selected={selectState} className={classes.OptionStyle} >{obj.activity}</option>}
        );
      //determine if current row has a selected activity, if not add in select activity option
      // if (!entries[key].activity_id) {
        // if (entries[key].activity_id === ''){
        activityOptions.unshift(
          <option value="Select"  hidden>Select Activity</option>)
        // }
      //   );
      // }
      //check what the status of the entry is and adjust the left border accordingly
      let leftBorder;    
      if (entries[key].status === statusMap['Open']){
        leftBorder = classes.RowColourOpen;
      }else if (entries[key].status === statusMap['Submitted']){
        leftBorder = classes.RowColourSubmitted;
      }
      //if there is a new row allow the user to remove it, as long as it is not saved in the DB
      let deleteRow;
      if (!entries[key].status){
        deleteRow = <button className={["btn btn-light", classes.BtnRemoveRow].join(' ')} onClick={() => {removeEntry(key)}}><span className="glyphicon glyphicon-remove"></span></button>
      }
      console.log('actualHours', entries[key].actual_hours)

      let inputClassName = ["form-control", classes.RowInput];
      if (entries[key].forecasted){
        inputClassName.push(classes.ForecastStyle)
      }

      if (entries[key].forecast_hours){
        disableStatus = true;
      }



      return (
          <div className={[classes.Row, leftBorder].join(' ')}>
            <div style={{width: '100%'}}>
              {deleteRow}
            </div>
            <div className={classes.RowItem}>
              <label for="project" className={classes.RowLabel}>Project:</label>
              <select
                name="project"
                onChange={(event) => onInputChange(key, "project_id", event.target.value)}
                defaultValue="Project"
                className={[classes.RowInput, "form-control"].join(' ')}
                value={projectId}
                disabled={disableStatus}
              >
                {projectOptions}
              </select>
            </div>
            <div className={classes.RowItem}>
              <label for="activity" className={classes.RowLabel}>Activity:</label>
              <select
                name="activity"
                onChange={(event) => onInputChange(key, "activity_id", event.target.value)}
                className={["form-control", classes.RowInput].join(' ')}
                // value={entries[key].activity_id}
                disabled={disableStatus}

              >
                {activityOptions}
              </select>
            </div>
            <div className={classes.RowItem}>
              <label for="hours" className={classes.RowLabel}>Hours:</label>
              <input 
                name="hours"
                type="number"
                inputMode='decimal'
                // value = {entries[key].forecast_hours && !entries[key].actual_hours ? entries[key].forecast_hours : entries[key].actual_hours}
                value = {+entries[key].actual_hours >=0 ? entries[key].actual_hours : ''}
                className={inputClassName.join(' ')} 
                pattern='^[0-9]*\.[0-9][0-9]$'
                step="0.01"
                onChange={(event) => onInputChange(key, 'actual_hours', +event.target.value)}
                // value={Number(entries[key].actual_hours).toString()}
                // onFocus={(event) => {event.target.value =''}}
                // onBlur={(event) => {
                //   if (event.target.value === ''){
                //     event.target.value = entries[key].forecast_hours && !entries[key].actual_hours ? Number(entries[key].forecast_hours).toString() : Number(entries[key].actual_hours).toString()}
                //   }}
                onBlur={(event) => {
                  if (!event.target.value){
                      console.log("NULLL")
                      onInputChange(key, 'actual_hours', null)
                  }
                  }}
                />
            </div>
            <div className={classes.RowItem}>
              <label for="comment" className={classes.RowLabel}>Comment:</label>
              <input
                name="comment"
                type="textarea"
                rows ='2'
                className={["form-control", classes.RowInput].join(' ')}
                onChange={(event) => {
                onInputChange(key, 'actual_comments', event.target.value);
                }}
                value={entries[key].actual_comments}/>
            </div>
          </div>
      );
    });
  }
  /*eslint no-extend-native: ["error", { "exceptions": ["Date"] }]*/
  Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

function getDates(startDate, stopDate) {
    var dateArray = []
    var currentDate = startDate;
    while (currentDate <= stopDate) {
        dateArray.push(new Date (currentDate));
        currentDate = currentDate.addDays(1);
    }
    return dateArray;
}
  let dateOptions;
  let dateArray;

  if (props.date){
    dateArray = getDates(new Date(props.date).addDays(-30), new Date(props.date).addDays(+14))
    console.log(props.date)
    }

    dateOptions = dateArray.map((dateObj, index) => {
      // console.log(dateSelectState, dateObj.toLocaleDateString().trim(0,10))
      //   if (dateObj.toLocaleDateString().trim(0,10) === props.date){
      //     console.log('DATES', props.date, '|||', dateObj)
      //   }
        return(
        <option 
          key={dateObj.toLocaleDateString()} 
          value={dateObj.toLocaleDateString("sv-SE", {timeZone: 'Australia/Sydney'}).trim(0,10)}
          // value={dateObj.toLocaleDateString("sv-SE", {timeZone: 'Australia/Sydney'}).trim(0,10)}
          // selected={selectState}
        >
        {dateObj.toDateString()}</option>)
    })
  

      console.log("props.date", props.date)



  
  return (
    <Fragment>
        <div className={classes.ContainerDateButtons}>
          <span className={classes.DateTitle}>Date: </span>
          <select 
          for='dates' 
          value={props.date} 
          default={props.date}
          onChange={(event) => {props.setDate(event.target.value)}}
          className="form-control-lg"
          >
          {dateOptions}
          </select>
        <hr/>
        </div>
    <div className={classes.Content}>
      {mappings}
      <button type="button" className={["btn btn-outline-info btn-circle btn-lg", classes.RoundButton].join(' ')}onClick={addNewRow}>+</button>
    </div>
    <div className={classes.ButtonHolder}>
      <hr/>
      <button className={["btn btn-success btn-lg", classes.BtnFormSubmit].join(' ')} onClick={() => {saveData(entries)}}>Save</button>
      <button className={["btn btn-success btn-lg", classes.BtnFormSubmit].join(' ')} onClick={() => {submitData(entries)}}>Submit</button>
    </div>
    </Fragment>
  );
};

export default DailyTSView;
