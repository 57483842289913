import {React, useEffect} from 'react';
import classes from './Login.module.css';
import { cognitoURLDashboard } from '../../../utilities/globalVariables';



const LoginDashboard = (props) => {

    useEffect(() => {
        // Set the parent component's state value when the child component is mounted
        if (localStorage.getItem('mode') !== null) {
            // If the key exists, update its value
            localStorage.setItem('mode', 'dashboard');
          } else {
            // If the key does not exist, create it
            localStorage.setItem('mode', 'dashboard');
          }
      }, [props]);



    return (
        <div className={classes.Login}>
            <a className="btn btn-block bdashboardtn-lg btn-outline-dark m-2" href={cognitoURLDashboard}>Register / Login</a>
        </div>
    )
}

export default LoginDashboard;