import { useState, useEffect, useCallback, Fragment } from 'react';
import { useHistory, useLocation } from "react-router-dom";

import ParamsForm from '../../../UI/ParamsForm/ParamsForm';
import SpinnerDark from "../../../UI/SpinnerDark/SpinnerDark";
import Modal from '../../../UI/Modal/Modal';
import Banner from '../../../UI/Banner/Banner';
import classes from './Requests.module.css';
import TertiaryNavbar from '../../../UI/TertiaryNavBar/TertiaryNavBar';
import BSTable from '../../../UI/BSTable/BSTable';

import axios from 'axios';
import {
    AWS_API,
    cognitoClientId,
    cognitoUserpoolId,
  } from "../../../utilities/globalVariables";
import { handleAwsApiError,
    handleBanner, 
    handleBannerText
} from "../../../utilities/functions";
import ApprovalsCentre from '../../ApprovalsCentre/ApprovalsCentre';


const Requests = (props) => {

    // UI State
    // const [loading, setLoading] = useState(false);
    const [loadingAddEntry, setLoadingAddEntry] = useState(false);
    const [error, setError] = useState(false);
    const [bannerText, setBannerText] = useState("");
    const [loadingRequests, setLoadingRequest] = useState(false);
    const [loadingUpdateForm, setLoadingUpdateForm] = useState(false);
    const [showUpdateRequestModal, setShowUpdateRequestModal] = useState(false);
    const [bannerTextClass, setBannerTextClass] = useState()

    // const [content, setContent] = useState();

    // data state
    const [newEntryData, setNewEntryData] = useState();
    const [requestTypes, setRequestTypes] = useState();    
    const [requestSubTypes, setRequestSubTypes] = useState();
    const [mode, setMode] = useState();
    const [tableData, setTableData] = useState();
    const [selected, setSelected] = useState([]);
    const [requestData, setRequestData] = useState();


    // react router history instance to navigate user to other pages
    let history = useHistory();
    const location = useLocation();

    const role = props.role;
    // console logs
    console.log("New Entry Data: ", newEntryData);
    console.log("Request Types: ", requestTypes);
    console.log("Request Subtypes: ", requestSubTypes);


    const postRequest = async (table, row) => {

        //close modal
        setLoadingAddEntry(true);

        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId},
        };
        const postData = {
            table: table,
            rows: [row]
        }

        try {
            console.log('postData: ', postData);
            // console.log('Entered');
            const res = await axios.post(AWS_API + 'resource-data/add-request-with-email', {postData: postData}, headers);
            console.log('response: ', res.data);
            handleBanner(setBannerText, 'Successfully Updated Records', 'alert alert-success', setBannerTextClass);
            // handleBannerText(setBannerText, 'Entry Added Successfully');
            setNewEntryData();
            
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error while updating user groups');
        }     
        
        // setShowInsertRowModal(false);
        setLoadingAddEntry(false);
    }

    const fetchData = useCallback(async(table, setFn) => {
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {table: table}
        };

        // call lambda function to get data
        try {
            const res = await axios.get(AWS_API + 'resource-data/fetch-data', config);
            console.log("Res: ", res)
            setFn(res.data);
        } catch (err) {
            setError(handleAwsApiError(err, history) ??"Error encountered while fetching master data");
            console.log("Error: ", err);
        }

    }, [history, props.token]);

    const fetchRequests = useCallback(async() => {
        setLoadingRequest(true);
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };

        // call lambda function to get requests
        try {
            const res = await axios.get(AWS_API + 'resource-data/fetch-requests', config);
            console.log("Res: ", res)
            setTableData(res.data);
        } catch (err) {
            setError(handleAwsApiError(err, history) ??"Error encountered while fetching master data");
            console.log("Error: ", err);
            setLoadingRequest(false);
        }
        setLoadingRequest(false);
    }, [history, props.token]);

    
    const handleModeChange = useCallback((newMode) => {
        setMode(newMode);
    }, []);
 
    //Fetches the request types and subtypes tables
    useEffect(() => {

        if (!mode){
            handleModeChange('make');
        }

        if (mode === 'make'){
            //set request type and request sub-type
            fetchData('request_types', setRequestTypes);
            fetchData('request_subtypes', setRequestSubTypes);
        } else if (mode === 'view'){
            fetchRequests();
        }

    }, [fetchData, handleModeChange, mode, fetchRequests]);

    const handleEditRequests = () => {
        if (selected.length !== 1){
            setError("Error: Please choose 1 request only.");
        } else {
            // sets the request data to the selected row
            setRequestData(tableData?.filter(req => req.request_id === selected[0])[0]);
            setShowUpdateRequestModal(true);
        }
    }

    const updateRequest = async() => {
        setLoadingUpdateForm(true);
        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId},
        };
        const postData = {
            table: 'requests',
            rows: [requestData]
        }

        try {
            console.log('postData: ', postData);
            // console.log('Entered');
            const res = await axios.post(AWS_API + 'resource-data/update-request', {postData: postData}, headers);
            console.log('response: ', res.data);
            handleBannerText(setBannerText, 'Entry Updated Successfully')
            setRequestData();
            setSelected([]);
            fetchRequests();
            
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error while updating user groups');
            setLoadingUpdateForm(false);

        }     
        setLoadingUpdateForm(false);
        setShowUpdateRequestModal(false)

        // setShowInsertRowModal(false);
    }

    let content;
    let updateRequestForm;
    if (mode === 'make'){
        // gets request types and subtypes
        const rtOptions = requestTypes?.map(type => ({value: type.id, label: type.request_type}));
        const rstOptions = requestSubTypes?.filter(subType => subType.request_type_id === newEntryData?.request_type_id)?.map((type => ({value: type.id, label: type.id + ' ' + type.request_subtype})));
        
        content = <SpinnerDark/>
        if (!loadingAddEntry){
            //gets params form
            content = (
                <div>
                    <h4>Citra Request Form</h4>

                    <ParamsForm
                        data={newEntryData}
                        setData={setNewEntryData}
                        config={[
                            {name: 'request_type_id', type: 'react-select', options: rtOptions, valueProperty: 'value'},
                            {name: 'request_subtype_id', type: 'react-select', options: rstOptions, valueProperty: 'value'},
                            {name: 'comment', type: 'text'},
                            {name: 'start_date', type: 'date'},
                            {name: 'end_date', type: 'date'}

                        ]}
                    />
                    <button className='btn btn-block btn-success' onClick={() => postRequest('requests', newEntryData)}>Submit</button>
                    <br/>
                </div>
            );
        }
    } else if (mode === 'view'){
        // gets request types and subtypes
        const rtOptions = requestTypes?.map(type => ({value: type.id, label: type.id + ' ' + type.request_type}));
        const rstOptions = requestSubTypes?.filter(subType => subType.request_type_id === requestData?.request_type_id)?.map((type => ({value: type.id, label: type.id + ' ' + type.request_subtype})));

        // update request form with data from selected row prefilled
        updateRequestForm = <SpinnerDark/>
        if (!loadingUpdateForm){
            updateRequestForm = (
                <div>
                    <h3>Update Request</h3>
                    <hr/>
                    <ParamsForm
                        data={requestData}
                        setData={setRequestData}
                        config={[
                            {name: 'request_type_id', type: 'react-select', options: rtOptions, valueProperty: 'value', label: 'Request Type'},
                            {name: 'request_subtype_id', type: 'react-select', options: rstOptions, valueProperty: 'value', label: 'Request Subtype'},
                            {name: 'comment', type: 'text', label: 'comment'},
                            {name: 'start_date', type: 'date', label: 'start_date'},
                            {name: 'end_date', type: 'date', label: 'end_date'}

                        ]}
                    />
                    <br/>
                    <button className='btn btn-block btn-success' onClick={() => updateRequest()}>Submit</button>

                </div>
            );
        }
        content = <SpinnerDark/>;
        if (!loadingRequests){
            console.log('Table Data: ', tableData)
            content = (
                <div>
                    <button className='btn btn-block btn-warning' style={{width: '125px', height: '25px', float: 'right'}} onClick={handleEditRequests}>Edit Selected Request</button>
                    <h4>View/Edit Request</h4>
                    <br/>
                    <BSTable
                        selected={selected}
                        setSelected={setSelected}
                        table="view_request_with_approvers"
                        data={tableData}
                        enableEdit
                    />
                </div>
            );
        }
    } else if (mode === 'accept'){
        content = <ApprovalsCentre email={props.email} token={props.token}/>
    }

    let tabConfig;
    tabConfig = [
        {label: 'Make a Request', value: 'make'},
        {label: 'My Requests', value: 'view'}
    ];

    if (role === 'SuperUser' || role === 'manager'){
        tabConfig.push({label: 'Accept/Reject Requests', value: 'accept'});
    }

    
    return (
        <Fragment>
            <Banner show={bannerText} bootstrapClass={bannerTextClass}>{bannerText}</Banner>  
            <div className={classes.Requests}>
                <Modal show={error} modalClosed={() => setError(false)}>
                    <h3>Oops, something went wrong!</h3>
                    <hr />
                    <p>{error}</p>
                </Modal>
                <Modal large tall show={showUpdateRequestModal} modalClosed={() => setShowUpdateRequestModal(false)}>
                    {updateRequestForm}
                </Modal>
                <div>
                    <TertiaryNavbar tab={mode} updateStateFn={handleModeChange} config={tabConfig} />
                    {content}
                </div>
            </div>
        </Fragment>

    );
}

export default Requests;