export const scrollPageToTop = () => {
    window.scrollTo(0, 0);
}

export const handleAwsApiError = (err, history) => {
    console.log('error', err)
    if (err.response && err.response.status === 403) {
        console.log('[handleAwsApiError fn] API call forbidden, redirecting to Auth.js');
        history.replace("/auth");
    } else if (err.response && err.response.status === 409) {
        console.log('409 Error Encountered, Message: ', err.response.data);
        return err.response.data;
    } else {
        console.log('Unknown Error Encountered in handleAWSApiError function: ', err.stack);
        if (err.response && err.response.data && err.response.data.message) {
            return err.response.data.message;
        } else if (err.response && err.response.data) {
            return err.response.data;
        } else if (err.stack) {
            return err.stack;
        } else {
            return null
        }
    }        
}

export const arraysMatch = (arr1, arr2) => {

	// Check if the arrays are the same length
	if (arr1.length !== arr2.length) return false;

	// Check if all items exist and are in the same order
	for (var i = 0; i < arr1.length; i++) {
		if (arr1[i] !== arr2[i]) return false;
	}

	// Otherwise, return true
	return true;

};

export const handleBanner = (bannerTextFn, text, bootstrapClass='alert alert-success', bannerClassfn) => {
    bannerTextFn(text);
    bannerClassfn(bootstrapClass);

    setTimeout(() => {
        bannerTextFn();
        bannerClassfn()
    }, 2000);
}

export const handleBannerText = (bannerTextFn, text) => {
    bannerTextFn(text);
    setTimeout(() => {
        bannerTextFn();
    }, 3000);    
}

// turn db names into more readable text
export const humanize = (str) =>  {

    var i, frags = str?.split('_');
    for (i=0; i<frags?.length; i++) {
        frags[i] = frags?.[i].charAt(0)?.toUpperCase() + frags?.[i]?.slice(1);
    }

    const text = frags?.join(' ');
    // return text;

    return text?.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1");

}

export const humanizeWithoutId = (str) => {
    str = humanize(str);

    if (str.slice(-3) === " Id"){
        str = str.slice(0,-3);
    }

    return str;
}


export const arrayObjsToCsv = (arr) => {

    // alert and break out of fn if not at least one row of data passed in
    console.log('[arrayObjToCsv] - array: ', arr);
    if (!arr?.length > 0) {
        alert('Please select at least one row to export');
        return false;
    }

    // get headers for first row of CSV
    const headers = Object.keys(arr[0]);
    console.log('headers: ', headers);

    const test = arr[0]
    console.log('test: ', test);
    const values = Object.values(test);
    console.log('values: ', values);


    // create CSV string
    let csvContent = "data:text/csv;charset=utf-8," 
    + headers.join(",") + "\n" 
    + arr.map(row => {

        const values = Object.values(row);
        const string = values.join(",").replaceAll("\n", " ");
        console.log('string: ', string);
        return string
        
    }).join("\n");    

    // encode and download CSV
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "report.csv");
    document.body.appendChild(link); // Required for FF
    
    link.click(); // This will download the data file named "my_data.csv".    
    return true;
}

export const datePickerObjToISOFormat = (date) => {
    const dateObj = typeof date.toDate === 'function' ? date.toDate() : date;
    const dateSQLFormat = dateObj.toLocaleDateString('sv-SW').slice(0,10);
    // const dateParts = dateStr.split("/");
    // const dateSQLFormat = dateParts[2] + "-" + dateParts[1] + "-" + dateParts[0];
    console.log('dataSqlFormat: ', dateSQLFormat);
    return dateSQLFormat;    
}

export const convertDbSavedDateFormat = (inputDate) => {
    // Create a Date object from the input string
    const date2 = new Date(inputDate);

    // Get the year, month, and day from the Date object
    const year2 = date2.getFullYear();
    const month2 = (date2.getMonth() + 1).toString().padStart(2, '0');
    const day2 = date2.getDate().toString().padStart(2, '0');

    // Format the Date components into "yyyy-mm-dd" format
    return `${year2}-${month2}-${day2}`;
}; 