import React from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../../assets/images/logo_clear.png';
import classes from '../Logo/Logo.module.css';

const logo = (props) => (
    <NavLink to="/">
            <div className={classes.Logo}>
                    <img src={Logo} alt="Citra Logo"/>
                    {/* <h6>&nbsp;&nbsp;CitrApp</h6>   */}
            </div>
            
    </NavLink>
);

export default logo;