// import classes from "./WeeklyTSView.module.css";
// import { useState, useEffect, useCallback } from "react";
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import Modal from '../../../UI/Modal/Modal';
import classes from './WeeklyTSView.module.css'
import { statusMap } from '../../../utilities/globalObjects';
import SpinnerDark from "../../../UI/SpinnerDark/SpinnerDark";

// import {useSwipeable} from 'react-swipeable';
const days = ['Mon','Tue','Wed','Thu','Fri','Sat', 'Sun'];
const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul","Aug", "Sep", "Oct", "Nov", "Dec"];

const WeeklyTSViews = (props) => {
    const [entries, setEntries] = useState({});
    const [rowTotals, setRowTotals] = useState([]);
    const [colTotals, setColTotals] = useState([])
    const [rowColTotal, setRowColTotals] = useState();
    const [showModal, setShowModal] = useState(false);
    const [modalRow, setModalRow] = useState([]);
    const [loading, setLoading] = useState(false)
    // const [closeButton, setCloseButton] = useState(false)
    // value={dateObj.toLocaleDateString("sv-SE", {timeZone: 'Australia/Sydney'}).trim(0,10)}
    // const prevMonday = useRef(new Date());
    // const nextSunday = useRef(new Date());
    let projects;
    let activities;
    const dateTitle = useRef();
    const dateWeek = useRef([]);
    let contents;
    let dateShort =  [];
    
    const setTotals = useCallback( (hours) => {
        console.log(hours, 'hours')
        const col = hours.length /7

        let rowArray = [];
        let colArray = [];

        let sum=0;
        for (let i =0; i < hours.length/7; i ++){
            sum =0;
            for (let j= 0; j < 7; j++){
                let rowCounter = (i*7) +j;
                console.log(hours[rowCounter].actual_hours)
                if (!hours[rowCounter].forecasted){
                    sum += +hours[rowCounter].actual_hours
                }
            }
            rowArray[i] = sum
        }

        for (let i =0; i < 7; i ++){
            sum = 0;
            for (let j =0; j < col; j++){
                let colCounter = i + (j*7)
                 if (!hours[colCounter].forecasted){
                    sum += +hours[colCounter].actual_hours
                }
            }
            colArray[i] = sum;
        }
        let rowColTotal =0;
        colArray.forEach(col => {rowColTotal += col})
        setRowColTotals(rowColTotal);
        setRowTotals(rowArray)
        setColTotals(colArray)
    }, [setRowTotals, setColTotals])

    useEffect(() => {
        setLoading(true);
        if (props.data){
            console.log("we here", props.date)
            let prevMonday;
            let nextSunday;
            //set previous monday
            prevMonday = new Date(props.date);
            prevMonday.setDate(prevMonday.getDate() - ((prevMonday.getDay() + 6) % 7));
            console.log(prevMonday, 'Monday');
            //set the next sunday, may not need next sunday date
            nextSunday = new Date(prevMonday);
            console.log('getDate',prevMonday.getDate());
            nextSunday.setDate(nextSunday.getDate() + 6);
            console.log(nextSunday, 'sunday')
            dateTitle.current =  `${months[new Date(prevMonday).getMonth()]} ${new Date(prevMonday).toLocaleDateString().substring(0,5)} ${new Date(prevMonday).getFullYear()}`
            dateWeek.current = getDates(prevMonday, nextSunday);
            console.log('DATEWEEK',dateWeek.current);


            //filter bookings that are inbetween current monday and sunday
            const filteredBookings = props.data.bookings.filter((obj) => {
                const currentDate = new Date(obj.date)
                return (currentDate >= prevMonday && currentDate <= nextSunday)
            })
            let newEntries = {}
            const reducedVals = Object.values(filteredBookings).map(obj => ({'project_id': obj.project_id, 'activity_id':obj.activity_id}));
            const uniqueVals = reducedVals.filter((value,index,self) => {return self.findIndex(v => v.activity_id === value.activity_id && v.project_id === value.project_id) === index});
            let loopCounter = 0;
            uniqueVals.forEach((element) => {
                dateWeek.current.forEach((date) => {
                    //sort out filtered bookings with what exists for that week, add default entries where records from the DB dont exist
                    console.log('this', typeof(date))
                    const fullMatch = Object.values(filteredBookings).filter(x => x.project_id === element.project_id && x.activity_id === element.activity_id && new Date(date).toLocaleDateString("sv-SE", {timeZone: 'Australia/Sydney'}) === new Date(x.date).toLocaleDateString("sv-SE", {timeZone: 'Australia/Sydney'}))
                    if (fullMatch && fullMatch.length > 0){
                        if (!fullMatch[0].actual_hours){
                            fullMatch[0].actual_hours = fullMatch[0].forecast_hours;
                            fullMatch[0].forecasted = true;
                        }
                        newEntries[loopCounter]= {...fullMatch[0]}
                    }else{
                        
                        newEntries[loopCounter] = {'project_id': element.project_id, 'activity_id': element.activity_id, 'date': date.toString(), actual_comments: '', actual_hours: null, resource_id: props.data.resourceId[0].resource_id}
                    }
                    loopCounter ++;
                })
            })
            console.log('NEWNEW', newEntries)
            setEntries(newEntries);
            setTotals(Object.values(newEntries));
            // setTotals(newEntries.r())
        }
    }, [setEntries, props.data, props.date, setTotals]) 

    if (dateWeek){
        dateWeek.current.forEach(date => {
            console.log(typeof("typeofDate",date))
            //insert short dates for displaying to the user
            dateShort.push(`${new Date(date).getDate()}/${new Date(date).getMonth() +1}`)

        })
    }

    console.log("DATESHORT", dateShort)


    //set dates fro earlier monday and later sunday
    // if (props.data) {
        // projects = props.data.projects;
        // activities = props.data.activities;
        // //set previous monday
        // prevMonday = new Date(props.date);
        // prevMonday.setDate(prevMonday.getDate() - ((prevMonday.getDay() + 6) % 7));
        // //set the next sunday, may not need next sunday date
        // nextSunday = new Date(props.date);
        // nextSunday.setDate(prevMonday.getDate() + 6)
        // dateTitle =  `${months[prevMonday.getMonth()]} ${prevMonday.toLocaleDateString().substring(0,5)} ${prevMonday.getFullYear()}`
        // dateWeek = getDates(prevMonday, nextSunday);
    // }
    console.table(Object.values(entries))
    
    const dateChangeHandler = (option) => {
        let newDate = new Date(props.date);
        if (option === 'decrease'){
            newDate.setDate(newDate.getDate() - 7)
            props.setDate(newDate.toLocaleDateString("sv-SE", {timeZone: 'Australia/Sydney'}).trim(0,10));
        }
        else if (option === 'increase') {
            newDate.setDate(newDate.getDate() + 7)
            props.setDate(newDate.toLocaleDateString("sv-SE", {timeZone: 'Australia/Sydney'}).trim(0,10));
        }
    }

    
    //take entries
    //map for each project
    //within activity, map for each 
    
    //when it maps out the rows, create un filled entry
    //allow zeros for comment and 
    
    //add new bookings after filtering down
    //ask david about weekends
    //implement back end checks against null hours
    /*eslint no-extend-native: ["error", { "exceptions": ["Date"] }]*/

    
    Date.prototype.addDays = function(days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }
    
    function getDates(startDate, stopDate) {
        var dateArray = []
        var currentDate = new Date(startDate);
        stopDate = new Date(stopDate)
        //added an extra day and sent while loops to less than
        while (currentDate.toLocaleDateString("sv-SE", {timeZone: 'Australia/Sydney'}) <= stopDate.toLocaleDateString("sv-SE", {timeZone: 'Australia/Sydney'})) {
            dateArray.push(currentDate.toLocaleDateString("sv-SE", {timeZone: 'Australia/Sydney'}));
            currentDate = currentDate.addDays(1);
        }
        setLoading(false);

        return dateArray;

    }
    
    if (props.data){
        projects = props.data.projects;
        activities= props.data.activities
    }
    
    console.log('activities', activities);
    console.log('projects', projects);

    // console.log(props.data)

    const onInputChange = (key, value, property, rowWide) => {



        if (property === 'actual_hours'){
            console.log(typeof(value), 'typeofd')
            console.log(value)
        }

        console.log('key', key)
        console.log('value', value)
        console.log('propert', property)
        console.log('rowWide', rowWide)
        console.log('entries', entries);
        let indexToChange = [];

    

        //multiple entires need to be changed as rowHeader (activity or project) has been changed
        if (rowWide === true){
            let num = key *7;
            for (let i =0; i < 7; i++){
                indexToChange.push(num+i);
            }
        }else{
            indexToChange.push(key);
        }
        console.log('indexTochange', indexToChange)
        let newEntries = {...entries}
        console.log('newEntries', newEntries)
        
        //if project and activity_id already exist then do not update and delete record
        if (property === 'activity_id'){
            const newActivity = value;
            const newProject = entries[key*7].project_id;

            const filteredEntries = Object.values(entries).filter(obj => obj.project_id === newProject && obj.activity_id === newActivity)

            if (filteredEntries.length > 0){
                alert('Project and Activity already exists, please try again')
                return false;
            }
        }
        //if user changes project set activity id to ''
        if (property === 'project_id'){
            for (const key of indexToChange){
                newEntries = {
                    ...newEntries,
                    [key]: {
                        ...entries[key],
                        activity_id: ''
                    }
                }
            }
        }
        const forecasted = (!property === 'actual_hours')
        for (const key of indexToChange){

            newEntries = {
                ...newEntries,
                [key]: {
                    ...newEntries[key],
                    [property]: value,
                    forecasted: forecasted
                },
                };
        }

        setEntries(newEntries);
        setTotals(Object.values(newEntries));
        console.log('updatedEntries', newEntries)

    }

    const submitData =  (entries) => {
        //change status to submitted before sending back to DB
        let newData = {};
        const keys = Object.keys(entries);
        console.log(keys[0])
        console.log("submit data keys", keys);
        console.log("curren Entries", entries)
    
        for (const key in keys){
          newData[key] = {...entries[key], 'status': statusMap['Submitted']}

          if (newData[key].actual_hours === null){
            delete newData[key]
        }
          
        }
        // for (let i = 0; i < keys.length; i++){
        //   newData[keys[i]] = {...entries[keys[i]], 'status': 'submitted'}
          
        // }
        console.log("Aftersubmite",newData)
        props.updateData(newData)
     }

     //what to do when user clicks submit, update object and set status if not yet saved or submitted by user
     const saveData = (entries) => {
    
        let newData = {};
        const keys = Object.keys(entries);
        for (const key in keys){
          newData[key] = {...entries[key]}
          if (!newData[key].status){
            newData[key].status = statusMap['Open']
          }
          //if actual hours are null then delete entry, this shouldnt affect forecasted records
          if (newData[key].actual_hours === null){
              delete newData[key]
          }
        }
        props.updateData(newData)
     }

     const setModalInfo = (row) => {
         console.log('entries', entries)
         console.log('row',row)
         let rowMin, rowMax;
         rowMin = row*7;
         rowMax = rowMin +6;
         console.log('min', rowMin, 'max', rowMax)
         
         let rowKeys = Object.keys(entries).filter((key, i) => {
             return key >= rowMin && key <= rowMax
            })
        setModalRow(rowKeys)
        
        setShowModal(true)
     }

    let contentArray = [<h4>Comments</h4>];
    if (entries && Object.keys(entries).length > 0){
        modalRow.forEach((key, i) => {
        // return 
            //determine if entrie has actual hours have any value, set text input to null if they dont
            let disabled = '';
            if (entries[key].actual_hours === null || entries[key].actual_hours === ''){
                disabled = 'disabled'
            }
            contentArray.push(

                <div className={classes.InnerModalDiv}>
                <span className={classes.CommentLabel}>{days[i]}</span>
                <textarea type='text' 
                className={['form-control', classes.CommentInputText].join(' ')}
                value={entries[key].actual_comments}
                onChange={(event) => {onInputChange(key, event.target.value, 'actual_comments', false)}}
                cols='2'
                rows='3'
                disabled={disabled}
                />
                </div>)
            console.log('actualComments', entries[key].actual_comments)
        })
    }

    const insertRow = () => {
        console.log(Object.keys(entries).length, 'LENGTH')
        let newEntries = {}
        console.log(dateWeek.current)
        console.log("")
        //check if there are any records for the week, if not create a new week
        if (Object.keys(entries).length === 0){
            dateWeek.current.forEach((element, i) => {
                newEntries[i] = {'project_id': '', 'activity_id': '', 'date': element, actual_comments: '', actual_hours: null, resource_id: props.data.resourceId[0].resource_id}
            })
            setEntries(newEntries)
            setTotals(Object.values(newEntries));
            return

        }
        if (entries[Object.keys(entries).length -1].project_id === '' || entries[Object.keys(entries).length -1].activity_id === '' ){
            //user has selected new row without adding project to previous row
            return
        }

        console.log('entries insertRow', entries)
        newEntries = {...entries};
        const entriesLength = Object.keys(entries).length;
        //compare against last entry, if no projectID or ActivityID then do not update 
        if(newEntries[entriesLength-1].project_id !== '' || newEntries[entriesLength-1].activity_id !== ''){
        dateWeek.current.forEach((element, i) => {

            newEntries[entriesLength+i] = {'project_id': '', 'activity_id': '', 'date': element, actual_comments: '', actual_hours: null, resource_id: props.data.resourceId[0].resource_id}
        })
        console.log('newEntriesINSERT', newEntries);
        console.log('dateweek', dateWeek.current)
        setEntries(newEntries)
        setTotals(Object.values(newEntries));
        // setCloseButton(true)
    }

    }
    let columnTotal;
    if (entries && Object.keys(entries).length > 0){
        columnTotal= <tr>
                <td></td>
                <th>Total</th>
                {colTotals.map(obj => {return <td className={classes.TdTotal}><span className={classes.ColTotal}>{obj}</span></td>})}
                <td></td>
                <td>{rowColTotal}</td>
            </tr>;
    }
    if (entries && Object.keys(entries).length > 0){
        const reducedVals = Object.values(entries).map(obj => ({'project_id': obj.project_id, 'activity_id':obj.activity_id}));
        const uniqueVals = reducedVals.filter((value,index,self) => {return self.findIndex(v => v.activity_id === value.activity_id && v.project_id === value.project_id) === index});
        console.log('new options render', uniqueVals)

        contents = uniqueVals.map((uniqueVal, row) => {
            console.log('uniqueObj', uniqueVal)
            // //project will always be assigned
            // let projectName = projects.find(project => project.project_id === uniqueVal.project_id).project_desc
            // //activty may not beassigned, need to account for possible null val
            // let activityName = activities.find(activity => activity.activity_id === uniqueVal.activity_id)
            // activityName = activityName === undefined ?  ' ' : activityName.activity 
            // const rowHeader = (<td>{projectName}{activityName}</td>)
            // console.log(Object.values(entries))

            let projectOptions = projects.map(project => {
                //set selected item 
                let selectState = '';
                if (+project.project_id === +uniqueVal.project_id){
                    selectState = 'selected';
                }
                return <option key={project.project_id} value={+project.project_id} selected={selectState}>{project.project_desc}</option>;

            })
            //check for now row being added and project id being empty string, add default select option if so
            if (uniqueVal.project_id === ''){
                projectOptions.unshift(<option value="project" key="default" selected hidden>Select</option>)
            }

            console.log(activities, 'activities')
            let activityOptions = activities.filter((activity) => {return +activity.project_id === +uniqueVal.project_id}).map((activityObj) => {
                
                
                //set selected item 
                console.log()
                let selectState ='';
                if (+activityObj.activity_id === +uniqueVal.activity_id){
                    selectState = 'true';
                }
                // if(Object.values(entries).filter(entry => {+entry.activity_id !== activity.activity_id }).length === 0){
                 return <option key={activityObj.activity_id} selected={selectState} value={activityObj.activity_id}>{activityObj.activity}</option>
                // return <option key={activity.activity_id} value={activity.activity_id} selected={selectState}>{activity.activity}</option>

            })
            //add default 'select' option
            activityOptions.unshift( <option value="Select" key="Select" select={true} hidden>Select</option>)
            
            //check entries against unique val project id and activity_id as well as forecast_hours not being null, set select menus as disabled if yes
            let disabledCheck = false;
            console.log('uniqueVals',uniqueVal.project_id, uniqueVal.activity_id)
            let forecastCheck = Object.values(entries).filter(obj => +obj.activity_id === +uniqueVal.activity_id && +obj.project_id === +uniqueVal.project_id && obj.forecast_hours);
            console.log('foreCast', forecastCheck)
            if (forecastCheck.length > 0){
                disabledCheck=true;
            } 

            let rowHeader = 
                <Fragment>
                    <td>
                        <select name="project" className={['form-control', classes.SelectDisabled].join(' ')} disabled={disabledCheck} onChange={(event) => {onInputChange(row, +event.target.value, 'project_id', true)}}>{projectOptions}</select>
                        {/* <select name="project" className={['form-control', classes.SelectDisabled].join(' ')} disabled={disabledCheck} onChange={(event) => {onInputChange(row, +event.target.value, 'project_id', true)}}>{projectOptions}</select> */}
                    </td>
                    <td>
                        <select name="activity" className={['form-control', classes.SelectDisabled].join(' ')}  disabled={disabledCheck} onChange={(event) => {onInputChange(row, +event.target.value, 'activity_id', true)}}>{activityOptions}</select>
                        {/* <select name="activity" className='form-control' defaultValue={activityOptions[0]} disabled={disabledCheck} onChange={(event) => {onInputChange(row, +event.target.value, 'activity_id', true)}}>{activityOptions}</select> */}
                    </td>
                </Fragment>;

            // let deleteRow;
            const rowData = Object.keys(entries).filter((key) => {
                return entries[key].project_id === uniqueVal.project_id && entries[key].activity_id === uniqueVal.activity_id
            }).map(rowDatum => {
                
                let classLists =['form-control form-control-sm', classes.TableInput]
                if (entries[rowDatum].forecasted){
                    classLists.push(classes.ForecastStyle)
    
                }
                let singleDataColour;
                if (entries[rowDatum].status === statusMap['Open']){
                    singleDataColour = classes.OpenedData

                }else if (entries[rowDatum].status === statusMap['Submitted']){
                    singleDataColour = classes.SubmittedData
                    
                }
                return (
                <td className= {[singleDataColour]}>
                    {/* <label for='hours'>Hours:</label> */}
                    <input
                    name="hours"
                    type="number"
                    inputMode='decimal'
                    value = {entries[rowDatum].actual_hours !== null ? entries[rowDatum].actual_hours : ''}
                    // value = {entries[rowDatum].forecast_hours && +entries[rowDatum].actual_hours >=0 ? Number(entries[rowDatum].forecast_hours).toString() : Number(entries[rowDatum].actual_hours).toString()}
                    // placeholder={entries[rowDatum].forecast_hours}
                    className={classLists.join(' ')}
                    onChange={(event) => {onInputChange(rowDatum, +event.target.value, 'actual_hours', false)}}
                    pattern='^[0-9]*\.[0-9][0-9]$'
                    // pattern='^\d*(\.\d{0,2})?$'
                    step='0.01'
                    // onFocus={(event) => {event.target.value =''}}
                    onBlur={(event) => {
                        if (!event.target.value){
                            console.log("NULLL")
                            onInputChange(rowDatum, null, 'actual_hours', false)
                        }
                        }}
                    />
                </td>)
            })
            rowData.push(<td><button className={['btn btn-secondary', classes.CommentButton].join(' ')} onClick={() => {setModalInfo(row)}}><span className="glyphicon glyphicon-comment" ></span></button></td>)
            console.log(rowTotals[row])
            rowData.push(<td>{rowTotals[row]}</td>);
            console.log('rowData',rowData)
            return <tr>
                {rowHeader}
                {rowData}
            </tr>
        })
    }

    // const deleteRow = () => {

    //     let startLoop = Object.keys(entries).length -1;
    //     const endLoop = startLoop-7;
    //     let newEntries = {...entries}
    //     for(startLoop; startLoop !== endLoop; startLoop--){
    //         delete newEntries[startLoop];
    //     }

    //     setEntries(newEntries)
    //     setTotals(Object.values(newEntries));
    //     // setCloseButton(false)

    // }
    let timesheet = <SpinnerDark/>;
    if (!loading){
        console.log('Not loading')
        timesheet = (
            <div>
                <div className={classes.ContainerDateButtons}>
                    <button className={classes.DateNavBtn} onClick={() => {dateChangeHandler('decrease')}}>&larr;</button>
                    <div className={classes.DateTitle}>{dateTitle.current}</div>
                    <button className={classes.DateNavBtn} onClick={() => {dateChangeHandler('increase')}}>&rarr;</button>
                </div>
                <hr></hr>
                <div className={classes.TableDiv}>
                    <table className={["table table-striped table-hover table-bordered", classes.Table].join(' ')}>
                        <thead>
                            <tr>
                                <th className={classes.SelectTH}>Project</th>
                                <th className={classes.SelectTH}>Activity</th>
                                <th className={classes.InputTH}>{`Mon ${dateShort[0]}`}</th>
                                <th className={classes.InputTH}>{`Tue ${dateShort[1]}`}</th>
                                <th className={classes.InputTH}>{`Wed ${dateShort[2]}`}</th>
                                <th className={classes.InputTH}>{`Thur ${dateShort[3]}`}</th>
                                <th className={classes.InputTH}>{`Fri ${dateShort[4]}`}</th>
                                <th className={classes.InputTH}>{`Sat ${dateShort[5]}`}</th>
                                <th className={classes.InputTH}>{`Sun ${dateShort[6]}`}</th>
                                <th className={classes.InputTH}>Comment</th>
                                <th className={classes.InputTH}>Total</th>
                                {/* <th>Total</th> */}
                            </tr>
                        </thead>
                            <tbody>
                                {contents}
                                {entries && columnTotal}
                            </tbody>
                            </table>
                {/* {closeButton && <button className={['btn btn-outline-danger btn-sm', classes.DeleteButton].join(' ')} onClick={deleteRow}><span className="glyphicon glyphicon-remove"></span></button>} */}
                </div>
                <button className={['btn btn-outline-info btn-cricle btn-lg', classes.RoundButton].join(' ')} onClick={insertRow}>+</button>
                <div className={classes.ButtonHolder}>
                <hr/>
                <button className={["btn btn-success btn-lg", classes.BtnFormSubmit].join(' ')} onClick={() => {saveData(entries)}}>Save</button>
                <button className={["btn btn-success btn-lg", classes.BtnFormSubmit].join(' ')} onClick={() => {submitData(entries)}}>Submit</button>
                </div>
            </div>);
    }
    
    return (
        <Fragment>
            <Modal 
                show={showModal}
                modalClosed={()=>{setShowModal(false)}}>
                    <div className={classes.Modal}>
                        {contentArray}    
                        <div className={classes.InnerModalDivButton}>
                            <hr></hr>
                            <button className={['btn btn-success', classes.ModalSaveButton].join(' ')} onClick={() => setShowModal(false)}>Save</button>
                            <button className={['btn btn-outline-danger', classes.ModalSaveButton].join(' ')} onClick={() => setShowModal(false)}>Back</button>
                        </div>    
                    </div>
            </Modal>
            {timesheet}
        </Fragment>
        
        );
    };
    
    export default WeeklyTSViews;