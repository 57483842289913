import { useState } from "react";
import { CompactPicker } from "react-color";

const ColourPicker = (props) => {
    const [colour, setColour] = useState('#000000');

    const handleColourChange = (newColour) => {
        setColour(newColour.hex);
        props.onChange(newColour.hex);
    };

    return (
        <div>
            <CompactPicker color={colour} onChange={handleColourChange}/>
        </div>
    )
};

export default ColourPicker;