import axios from "axios";
import { useState, useEffect, useCallback, Fragment } from "react";
import { useHistory } from "react-router-dom";
import {
  AWS_API,
  cognitoClientId,
  cognitoUserpoolId,
} from "../../../utilities/globalVariables";
import classes from "./ResourceAssign.module.css";
import { handleAwsApiError } from "../../../utilities/functions";
import Modal from "../../../UI/Modal/Modal";
import SpinnerDark from "../../../UI/SpinnerDark/SpinnerDark";
import Banner from "../../../UI/Banner/Banner";
import { handleBanner } from "../../../utilities/functions";
const ResourceAssign = (props) => {

  // UI state
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  // data state
  const [resources, setResources] = useState();
  const [projects, setProjects] = useState();
  const [activities, setActivities] = useState();
  const [forecastHours, setForecastHours] = useState(8);
  const [forecastType, setForecastType] = useState(20);
  const [bannerText, setBannerText] = useState();
  const [bannerClassText, setBannerClassText] = useState()

  // two way binding state
  const [selectedProject, setSelectedProject] = useState();
  const [selectedResource, setSelectedResource] = useState();
  const [selectedActivity, setSelectedActivity] = useState();
  const [selectedToDate, setSelectedToDate] = useState();
  const [selectedFromDate, setSelectedFromDate] = useState();

  // react router history instance to navigate user to other pages
  let history = useHistory();

  const fetchData = useCallback( async (table, updateFunction) => {
    const config = {
        headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        params: {table: table}
    };
    
    // call lambda function to get master data
    try {
        const res = await axios.get(AWS_API + 'master-data/', config);
        updateFunction(res.data);
      } catch (err) {
        setError(handleAwsApiError(err, history) ??"Error encountered while fetching master data");
        console.log("Error: ", err);
      }
  }, [ history, props.token]);

    // console.log('projects: ', projects);
    // console.log('resources: ', resources);
    // console.log('activities: ', activities);

    useEffect(() => {
        setLoading(true)

        // only call fetch data function once props.token has been received
        if (props.token) {
            fetchData('resources', setResources);
            fetchData('projects', setProjects);
            fetchData('activities', setActivities);
            console.log('calling fetch data');
        }
        setLoading(false)
    
    }, [props.token, setLoading, fetchData]);


    // create select options 
    let resourceOptions, projectOptions;
    if (resources && projects) {
        resourceOptions = resources.map(obj => {
            return (
                <option value={obj.resource_id}>{obj.email}</option>
            );
        });
        resourceOptions.unshift(<option disabled value="Select Resource" selected hidden>Select Resource</option>)
        
        projectOptions = projects.map(obj => {
            return (
                <option value={obj.project_id}>{obj.project_desc}</option>
                );
            })
        projectOptions.unshift(<option disabled value="Select Project" selected hidden>Select Project</option>)
        
        
        
        
    }
    const resourceSelect = <select className="form-control" onChange={(e) => setSelectedResource(e.target.value)} value={selectedResource}>{resourceOptions}</select>;
    const projectSelect = <select className="form-control" onChange={(e) => setSelectedProject(e.target.value)} value={selectedProject}>{projectOptions}</select>;
    
    // determine activities relevant to project
    let activityOptions = [];
    if (activities && selectedProject) {
        activityOptions = activities.filter(obj => +obj.project_id === +selectedProject).map(obj => {
            return (
                <option value={obj.activity_id} >{obj.activity}</option>
            );
        });
        activityOptions.unshift(<option disabled value='Select Activity' selected hidden>Select Activity</option>)
        console.log(activityOptions)
        // console.log('activityOptiosn: ', activityOptions)
    }

    let forecastHoursContent = [];
    forecastHoursContent.push(<input className="form-control"
                     type='number'
                     value= {forecastHours}
                     onChange={((event) => {setForecastHours(event.target.value)})}
                     pattern='^\d*(\.\d{0,2})?$'
                     onFocus={(event) => {event.target.value =''}}/>);

    let forecastTypeContent = [];
    forecastTypeContent.push(
        <select className="form-control" name='forecastType' onChange={(event) => {setForecastType(event.target.value); console.log(event.target.value)}}>
            <option value='20'>Confirmed</option>
            <option value='30'>Tentative</option>
        </select>)
    
    // activityOptions.unshift(<option disabled value="Select Activity">Select Activity</option>)
    // activityOptions.unshift(<option disabled value="Select Activity">null</option>)
    const activitySelect = <select className="form-control" onChange={(e) => setSelectedActivity(e.target.value)} value={selectedActivity}>{activityOptions}</select>;
    
    // console.log('selectedProject: ', selectedProject);
    console.log('selectedActivity: ', selectedActivity);
    // console.log('selectedFrom: ', selectedFromDate);
    // console.log('selectedTo: ', selectedToDate);
    // console.log('project options; ', projectOptions);
    
 
    // once options have been set, initialise state 
    // useEffect(() => {

    //     if (projectOptions && projectOptions.length > 0 && resourceOptions && resourceOptions.length > 0) {
    //         setSelectedResource(resourceOptions[0]);
    //         setSelectedProject(projectOptions[0]);

    //     }

    //     if (activityOptions && activityOptions.length > 0) {
    //         setSelectedActivity(activityOptions[0]);

    //     }
    // }, [resourceOptions, projectOptions, activityOptions])

    const submit = async () => {
        
        const postData = {
            resourceId: selectedResource,
            projectId: selectedProject,
            activityId: selectedActivity,
            fromDate: selectedFromDate,
            toDate: selectedToDate,
            forecastHours: forecastHours,
            forecastType: forecastType
        };
        console.log('POSTDAT',postData)
        //check if any null values present, throw error if true
        let data = Object.values(postData).filter(value => value ===undefined)
        console.log(data)
        if (data.length > 0){
            handleBanner(setBannerText, 'Please Fill Out All Options', 'alert alert-danger', setBannerClassText)
            return;
        }
        setLoading(true);
        console.log(postData, 'postData')
        const headers = {headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}};
        console.log('posting data: ', postData);
        // call lambda function to update master data item
        try {
            const res = await axios.post(AWS_API + 'master-data/assign-resource', {postData: postData}, headers);
            console.log('postMasterData response: ', res.data);
            handleBanner(setBannerText, 'Successfully Assigned Resources', 'alert alert-success', setBannerClassText)
            
        } catch (err) {
            console.log(err, 'error')
            setError(handleAwsApiError(err, history) ?? 'Error encountered while attempting to update master data, transaction aborted');
        } finally {
            setLoading(false);
        }  
    }
    console.log(forecastType)

    let content = <SpinnerDark />;
    if (!loading) {
        content = (
            <Fragment>
                <div className={classes.Row}>
                    <label for="activity" className={classes.RowLabel}>Resource:</label>
                    {resourceSelect}
                </div>
                <div className={classes.Row}>
                    <label for="activity" className={classes.RowLabel}>Project:</label>
                    {projectSelect}
                </div>
                <div className={classes.Row}>
                    <label for="activity" className={classes.RowLabel}>Activity:</label>
                    {activitySelect}
                </div>
                <div className={classes.Row}>
                    <label for="forecastHours" className={classes.RowLabel}>Forecast Hours:</label>
                    {forecastHoursContent}
                </div>
                <div className={classes.Row}>
                    <label for="activity" className={classes.RowLabel}>Forecast Type:</label>
                    {forecastTypeContent}
                </div>
                <div className={classes.Row}>
                    <label for="activity" className={classes.RowLabel}>From:</label>
                    <input className="form-control" type="date" onChange={(e) => setSelectedFromDate(e.target.value)} value={selectedFromDate} />
                </div>
                <div className={classes.Row}>
                    <label for="activity" className={classes.RowLabel}>To:</label>
                    <input className="form-control" type="date" onChange={(e) => setSelectedToDate(e.target.value)} value={selectedToDate} />
                </div>
                <p> </p>
                <button onClick={submit} className="btn btn-success btn-block">Submit</button>

            </Fragment>

        );
    }
    
    return (
        <Fragment>
            <Banner show={bannerText} bootstrapClass={bannerClassText}>{bannerText}</Banner>
            <div className={classes.ResourceAssign}>
                <h4>Assign Resources</h4>
                {content}
                <Modal show={error} modalClosed={() => setError(false)}>
                <h3>Oops, something went wrong!</h3>
                <hr />
                <p>{error}</p>
                </Modal>
            </div>
        </Fragment>
    );
};

export default ResourceAssign;
