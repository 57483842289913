import React from 'react';

import classes from './NavigationItems.module.css';
import NavigationItem from './NavigationItem/NavigationItem';
import Aux from '../../../hoc/Auxiliary/Auxiliary';

const navigationItems = (props) => {

    let roleSpecificLinks = null;
    let infoItems = null;
    let logout = null;
  
    if (props.role === 'SuperUser') {
      roleSpecificLinks = (
        <Aux>
              <NavigationItem link="/reporting">Reporting</NavigationItem>
              <NavigationItem link="/timesheet">Timesheet</NavigationItem>
              <NavigationItem link="/masterdata">Data</NavigationItem>
              <NavigationItem link="/assign-resource">Assign Resources</NavigationItem>
              <NavigationItem link="/links">Links</NavigationItem>
              <NavigationItem link="/requests">Requests</NavigationItem>
              <NavigationItem link="/work-locations">Work Locations</NavigationItem>
        </Aux>
      );
    } else if (props.role === 'User') {

      roleSpecificLinks = (
        <Aux>
            <NavigationItem link="/timesheet">Time Entry</NavigationItem>            
            <NavigationItem link="/links">Links</NavigationItem>
            <NavigationItem link="/requests">Requests</NavigationItem>
            <NavigationItem link="/work-locations">Work Locations</NavigationItem>
        </Aux>
      );     
    } else {
      roleSpecificLinks = (
        <Aux>
              <NavigationItem link="/">Home</NavigationItem>
            </Aux>
          );            
    }
  
    if (props.role) {
      infoItems = (
        <Aux>
          <NavigationItem infoOnly>{props.role}</NavigationItem>
          <NavigationItem infoOnly >{props.email}</NavigationItem>
          
        </Aux>
      );

      logout = <NavigationItem  infoOnly clicked={props.signout}>Logout</NavigationItem>;
    }

    return (
    <ul className={classes.NavigationItems}>
          {infoItems}
          {roleSpecificLinks}
          {logout}

    </ul>

    );

}

export default navigationItems;