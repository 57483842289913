import React from 'react';
import SmallSpinnerLight from '../SmallSpinnerLight/SmallSpinnerLight';

import classes from './BackdropSpinner.module.css';

const BackdropSpinner = (props) => {

    let spinner;
    if (props.spinner === 'small-light') {
        spinner = <SmallSpinnerLight/>;
    }

    return (
        <div className={classes.Backdrop}>
            <div className={classes.BackdropSpinner}>
                {spinner}
            </div>
        </div>

    );
}


  
export default BackdropSpinner;