
import classes from './WorkLocations.module.css';
import SpinnerDark from "../../UI/SpinnerDark/SpinnerDark";
import WorkLocationsInputs from './WorkLocationsInputs/WorkLocationsInputs';
import { useState, useEffect, useRef, useCallback, Fragment } from 'react';
import axios from "axios";
import {
  AWS_API,
  cognitoClientIdDashboard,
  cognitoUserpoolIdDashboard,
} from "../../utilities/globalVariables";
import { handleAwsApiError } from "../../utilities/functions";
// import Banner from "../../UI/Banner/Banner";
import { useHistory, useLocation } from "react-router-dom";
import Modal from '../../UI/Modal/Modal';
import Banner from '../../UI/Banner/Banner';
import { handleBanner, humanize, convertDbSavedDateFormat } from '../../utilities/functions';

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul","Aug", "Sep", "Oct", "Nov", "Dec"];
const days = ['Mon','Tue','Wed','Thu','Fri'];

const WorkLocationsDashboard = (props) => {
    // set states and refs
    const [managingResources, setManagingResources] = useState([]);
    const [filteredManagingResources, setFilteredManagingResources] = useState([]);
    const [resources, setResources] = useState();
    const [filteredResources, setFilteredResources] = useState();
    const [currentDate, setCurrentDate] = useState(new Date());
    const [workLocationTypes, setWorkLocationTypes] = useState([]);
    const [currentWorkLocations, setCurrentWorkLocations] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [displayDate, setDisplayDate] = useState();
    const [bannerText, setBannerText] = useState();
    const [bannerTextClass, setBannerTextClass] = useState();
    const [showCommentModal, setShowCommentModal] = useState(false);
    const [modalResourceId, setModalResourceId] = useState();

    const dateTitle = useRef();
    const dateWeek = useRef([]);


    const location = useLocation();
    const history = useHistory();
    // console.log("Display Date: ", displayDate);

    Date.prototype.addDays = function(days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }
    
    const fetchData = useCallback(async(table, setFn) => {
        setLoading(true);
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientIdDashboard, userpoolid: cognitoUserpoolIdDashboard},
            params: { table: table }
        }

        try {
            const res = await axios.get(AWS_API + 'resource-data/fetch-data', config);
            // console.log("res: ", res.data);
            setFn(res.data);
        } catch (err) {
            setError(handleAwsApiError(err, history) ??"Error encountered while fetching master data");
            console.log("Error: ", err);
        } finally {
            setLoading(false);
        }
    }, [props.token, history]);



    const fetchResourcesForManager = useCallback(async() => {
        setLoading(true);
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientIdDashboard, userpoolid: cognitoUserpoolIdDashboard},
        };

        try{
            // retrieve managing resources, for people are not managers, just returns their own resource id
            // const res = await axios.get(AWS_API + 'resource-data/fetch-resources-for-manager', config);
            // console.log("res: ", res.data);
            setManagingResources([]);
        } catch (err){
            setError(handleAwsApiError(err, history) ??"Error encountered while fetching resources");
            console.log("Error: ", err);
        } finally {
            setLoading(false);
        }
    }, [props.token, history]);

    // const submitWorkLocations = async(locationData) => {
    //     // console.log("Submitting Work Locations");
    //     setLoading(true);
    //     const config = {
    //         headers: {
    //             authorization: props.token,
    //             appclientid: cognitoClientIdDashboard,
    //             userpoolid: cognitoUserpoolIdDashboard,
    //             email: props.email,                cognitoClientIdDashboard
    //         }
    //     };

    //     const postData = {
    //         locationData: locationData
    //     };
    //     // console.log("Post Data: ");
    //     // updates work locations to database
    //     try {
    //         const res = await axios.post(AWS_API + 'resource-data/post-work-locations', {postData: postData}, config);
    //         console.log("res.data: ", res.data);
    //         fetchData('work_locations', setCurrentWorkLocations);
    //         handleBanner(setBannerText, 'Successfully Updated Records', 'alert alert-success', setBannerTextClass);
    //     } catch (err){
    //         setError(handleAwsApiError(err, history, location) ?? 'Unexpected error while updating user groups');

    //     } finally {
    //         setLoading(false);
    //     }
        
        
    // };

    const getDates = (startDate, stopDate) => {
        setLoading(true);
        var dateArray = []
        var date = new Date(startDate);
        stopDate = new Date(stopDate);
        //added an extra day and sent while loops to less than
        while (date.toLocaleDateString("sv-SE", {timeZone: 'Australia/Sydney'}) <= stopDate.toLocaleDateString("sv-SE", {timeZone: 'Australia/Sydney'})) {
            dateArray.push(date.toLocaleDateString("sv-SE", {timeZone: 'Australia/Sydney'}));
            date = date.addDays(1);
        }
        setLoading(false);

        return dateArray;

    };

    
    useEffect(() => {
        setLoading(true);
        fetchResourcesForManager();
        fetchData('work_location_types', setWorkLocationTypes);
        fetchData('work_locations', setCurrentWorkLocations);
        fetchData('resources', setResources);
        setLoading(false);
    }, [fetchResourcesForManager, fetchData, setWorkLocationTypes]);
    
    useEffect(() => {
        setCurrentDate(new Date().toLocaleDateString("sv-SE", {timeZone: 'Australia/Sydney'}).trim(0,10));
    }, [setCurrentDate]);
    

    useEffect(() => {
        if (resources){
            setFilteredResources(resources
                .filter(resource => Date.now() < new Date(convertDbSavedDateFormat(resource.valid_to)))
                .sort((a, b) => a.email.localeCompare(b.email)));
        }
    }, [resources]);

    useEffect(() => {
        if (filteredResources){
            setFilteredManagingResources(managingResources.filter(resource => filteredResources.some(filteredResource => filteredResource.resource_id === resource.resourceId)));
        }
    }, [filteredResources, managingResources]);
    
    // set dates in work locations
    useEffect(() => {
        
        setLoading(true);
        // if (filteredManagingResources?.length > 0 && workLocationTypes?.length > 0){
           if (workLocationTypes?.length > 0){
            let prevMonday;
            let nextSunday;
            prevMonday = new Date(currentDate);
            prevMonday.setDate(prevMonday.getDate() - ((prevMonday.getDay() + 6) % 7));
            // set the next sunday, may not need next sunday date
            nextSunday = new Date(prevMonday);
            nextSunday.setDate(nextSunday.getDate() + 6);
            dateTitle.current =  `${months[new Date(prevMonday).getMonth()]} ${new Date(prevMonday).toLocaleDateString().substring(0,5)} ${new Date(prevMonday).getFullYear()}`
            dateWeek.current = getDates(prevMonday, nextSunday);
            
            setDisplayDate(dateTitle?.current);
            setLoading(false);
        }
        
    // }, [currentDate, filteredManagingResources, workLocationTypes]);
       }, [currentDate, workLocationTypes]);
    
    // handles date changes
    const dateChangeHandler = (option) => {
        let newDate = new Date(currentDate);
        if (option === 'decrease'){
            newDate.setDate(newDate.getDate() - 7)
            setCurrentDate(newDate.toLocaleDateString("sv-SE", {timeZone: 'Australia/Sydney'}).trim(0,10));
        }
        else if (option === 'increase') {
            newDate.setDate(newDate.getDate() + 7)
            setCurrentDate(newDate.toLocaleDateString("sv-SE", {timeZone: 'Australia/Sydney'}).trim(0,10));
        }
    };
    
    let dateShort =  [];
    let years = [];
    if (dateWeek){
        // console.log("Date Week Current: ", dateWeek?.current);
        dateWeek.current.forEach(date => {
            //insert short dates for displaying to the user
            dateShort.push(`${new Date(date).getDate()}/${new Date(date).getMonth() +1}`);
            years.push(date.substring(0,4));
        })
    }

    const handleCommentModal = (resourceId) => {
        setModalResourceId(resourceId);
        setShowCommentModal(true);
    }

    let tableContents;
    // console.log('currentWorkLocations: ', currentWorkLocations);
    let comments = [];
    let requiredWorkLocationInfo = [];
    if (filteredResources?.length > 0 && currentWorkLocations.length > 0){
        // add table content for each resource
        tableContents = filteredResources.map((resource) => {
            const resourceId = resource?.resource_id;
            const email = resource?.email;
            const name = email?.split('@')[0];
            const firstName = name?.split('.')[0];
            //const lastName = name?.split('.')[1]; -- Give Deepak an Alias
            const lastName = name?.split('.')[1]?.toLowerCase() === "sahu" ? "Kumar" : name?.split('.')[1];
            const fullName = humanize(firstName) + humanize(lastName);
            // Show resource name in left column
            let rowHeader = (
                <Fragment>
                    <td>
                        <span>{fullName}</span>
                    </td>   
                </Fragment>
            )

            let rowData = [];
            for (let i = 0; i < 5; i++){
                const parts1 = `${dateShort[i]}/${years[i]}`.split('/');
                const formattedDate1 = `${parts1[2]}-${parts1[1].padStart(2, '0')}-${parts1[0].padStart(2, '0')}`;
                // get the resource work location data for the current date
                const workLocationRow = currentWorkLocations.find(obj => obj?.resource_id === resourceId && convertDbSavedDateFormat(obj?.date) === formattedDate1);
                requiredWorkLocationInfo.push(workLocationRow);
                if (workLocationRow){
                    // get the location once found
                    const locationTypeData = workLocationTypes?.find(obj => obj?.location_id === workLocationRow?.location_id);
                    if (locationTypeData){
                        rowData.push(
                            <Fragment>
                                <td style={{backgroundColor: locationTypeData?.colour}}>
                                    
                                </td>
                            </Fragment>
                        );
                    }
                } else {
                    const targetDate = new Date(formattedDate1);
                    // filter to only include dates before the target date, on the same day and the current resource
                    const latestDateBeforeTarget = currentWorkLocations
                        .filter(obj => new Date(convertDbSavedDateFormat(obj.date)).getDay() === targetDate.getDay() && new Date(convertDbSavedDateFormat(obj.date)) < targetDate && obj.resource_id === resourceId)
                        .sort((a,b) => new Date(convertDbSavedDateFormat(b.date)) - new Date(convertDbSavedDateFormat(a.date)))
                        .shift();
                    if (latestDateBeforeTarget){
                        //Since we are taking last weeks date, we have to update the date 
                        //Otherwise we will not have proper date for work location count 
                        //Later on, for the last two two rows. This wasnt causing a problem 
                        //In the table because it had nothing to do with date, we are dynamically
                        //Creating table entries
                        latestDateBeforeTarget.date = targetDate;
                        requiredWorkLocationInfo.push(latestDateBeforeTarget);
                        // get the location once found
                        const locationTypeData = workLocationTypes?.find(obj => obj?.location_id === latestDateBeforeTarget?.location_id);  
                        if (locationTypeData){
                            rowData.push(
                                <Fragment>
                                    <td style={{backgroundColor: locationTypeData?.colour}}>
                                    
                                    </td>
                                </Fragment>
                            );
                            const comment = latestDateBeforeTarget?.comment;
                            if (comment){
                                comments.push({
                                    resourceId: latestDateBeforeTarget.resource_id,
                                    comment: comment,
                                    day: i
                                });
                            }
                        }
                    } else {
                        rowData.push(
                            <Fragment>
                                <td>
                                    &nbsp;
                                </td>
                            </Fragment>
                        );                        
                    }
                }

            }
            rowData.push(<td><button className={['btn btn-secondary', classes.CommentButton].join(' ')} onClick={() => handleCommentModal(resourceId)}><span className="glyphicon glyphicon-comment" ></span></button></td>)

            return (
                <Fragment>
                    <tr>   
                        {rowHeader}
                        {rowData}
                    </tr>
                </Fragment>
            );
        });




    }



    let legend = [];
    if (workLocationTypes){
        workLocationTypes.forEach((locationType) => {
            legend.push(
                <div className={classes.LegendFlex}>
                    <p className={classes.LegendCaption}>{locationType?.location_type} → &nbsp;</p>
                    <div style={{
                        backgroundColor: locationType?.colour,
                        width: '50px',
                        height: '15px',
                    }}>

                    </div>   
                </div>
            );
        });
    }

    let commentContent = [<h3>Comments</h3>];
    if (showCommentModal && modalResourceId){
        for (let i=0; i<5; i++){
            const parts1 = `${dateShort[i]}/${years[i]}`.split('/');
            const formattedDate1 = `${parts1[2]}-${parts1[1].padStart(2, '0')}-${parts1[0].padStart(2, '0')}`;
            // console.log("Comments: ", comments);
            let comment;
            comment = currentWorkLocations?.find(obj => convertDbSavedDateFormat(obj?.date) === formattedDate1 && obj?.resource_id === modalResourceId)?.comment;
            if (!comment){
                comment = comments.find(obj => obj.day === i && obj.resource_id === modalResourceId);
            }
            commentContent.push(
                <div>
                    <br/>
                    <p>{days[i]}</p>
                    <textarea 
                        key={modalResourceId}
                        type='text' 
                        className={['form-control', classes.CommentInputText].join(' ')}
                        value={comment}
                        cols='2'
                        rows='3'
                        disabled={true}
                    />
                </div>
            );
        }
    }
    
    let locations = <SpinnerDark/>;
    // set locations jsx element
    //---------------------------Work Location Capacity Display-----------------------------------
    if (!loading && currentDate){
        let officeCapacityTable;
        if(requiredWorkLocationInfo.length>0){
            const formattedDates = [];
            for (let i = 0; i<5; i++){
                const parts1 = `${dateShort[i]}/${years[i]}`.split('/');
                const formattedDate1 = `${parts1[2]}-${parts1[1].padStart(2, '0')}-${parts1[0].padStart(2, '0')}`;
                formattedDates.push(formattedDate1);
            }
            const dateToWorkLocations = {};
            formattedDates.forEach((date) => {
                dateToWorkLocations[date] = 0
            }
            );
            currentWorkLocations.forEach((workLocation) => {
                const date = convertDbSavedDateFormat(workLocation.date);
                if (workLocation !== undefined && formattedDates.includes(date)) {
                    if (workLocation.location_id === 1) {
                        dateToWorkLocations[date] += 1;
                    };
                }
            });
            let rowHeader1 = (
                <Fragment>
                    <td>
                        <span>Actual</span>
                    </td>   
                </Fragment>
            );
            let rowHeader2 = (
                <Fragment>
                    <td>
                        <span>Required  </span>
                    </td>   
                </Fragment>
            );
            let row1 = [];
            let row2 = [];
            for(let i=0;i<5;i++){
                row1.push(
                    <Fragment>
                    <td>
                        <span>7</span>
                    </td>   
                </Fragment>
                )
            };
            Object.keys(dateToWorkLocations).forEach((date)=>{
                const info = dateToWorkLocations[date];
                row2.push(
                    <Fragment>
                    <td style={{backgroundColor: info>7 ? 'red' : 'white'}}>
                        <span>{info}</span>
                    </td>   
                </Fragment>
                );
            });

            officeCapacityTable = 
            (
            <Fragment>
            <tr>   
                {rowHeader1}
                {row1}
            </tr>   

            <tr>   
                {rowHeader2}
                {row2}
            </tr>
            </Fragment>
            );

            //Second Way
            // tableContents.push(officeCapacityTable);
        }
        locations = (
            <div className={classes.WorkLocations}>
                <h1>Citra Work Locations</h1>
                {/* <hr/> */}
                <div className={classes.ContainerDateButtons}>
                    <button className={classes.DateNavBtn} onClick={() => {dateChangeHandler('decrease')}}>&larr;</button>
                    <div className={classes.DateTitle}>{displayDate}</div>
                    <button className={classes.DateNavBtn} onClick={() => {dateChangeHandler('increase')}}>&rarr;</button>
                </div>
                <hr></hr>
                {/* <br/> */}
                <div className={classes.TableDiv}>
                    <table className={["table table-striped table-hover table-bordered", classes.Table].join(' ')}>
                        <thead>
                            <tr>
                                <th className={classes.SelectTH}>Resource</th>
                                <th className={classes.InputTH}>{`Mon ${dateShort[0]}`}</th>
                                <th className={classes.InputTH}>{`Tue ${dateShort[1]}`}</th>
                                <th className={classes.InputTH}>{`Wed ${dateShort[2]}`}</th>
                                <th className={classes.InputTH}>{`Thur ${dateShort[3]}`}</th>
                                <th className={classes.InputTH}>{`Fri ${dateShort[4]}`}</th>
                                <th className={classes.InputTH}>Comment</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableContents}
                        </tbody>
                    </table>
                    <table className={["table table-striped table-hover table-bordered", classes.Table].join(' ')}>
                        <thead>
                            <tr>
                                <th className={classes.SelectTH}>Office Capacity</th>
                                <th className={classes.InputTH}>{`Mon ${dateShort[0]}`}</th>
                                <th className={classes.InputTH}>{`Tue ${dateShort[1]}`}</th>
                                <th className={classes.InputTH}>{`Wed ${dateShort[2]}`}</th>
                                <th className={classes.InputTH}>{`Thur ${dateShort[3]}`}</th>
                                <th className={classes.InputTH}>{`Fri ${dateShort[4]}`}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {officeCapacityTable}
                        </tbody>
                    </table>
                </div>
                <hr/>
                <div className={classes.Legend}>
                    <h4>Legend</h4>
                    <div className={classes.LegendFlex}>
                        {legend}
                    </div>
                </div>
                <hr/>
                {/* <div className={classes.TableDiv}>
                <WorkLocationsInputs 
                    dateShort={dateShort} 
                    managingResources={filteredManagingResources} 
                    workLocationTypes={workLocationTypes} 
                    submitWorkLocations={submitWorkLocations} 
                    years={years} rowData
                    managingResourceWorkLocations={currentWorkLocations?.filter(workLocation => {
                        return filteredManagingResources?.some(resource => resource.resourceId === workLocation?.resource_id);
                    })}
                />
                </div> */}
            </div>

        );

        // //Rendeting the newly created component
        // const newRowDate = [];
        // for (let i = 0; i<2; i++){
        //     let rowHeader = (
        //         <Fragment>
        //             <td>
        //                 <span>Capacity</span>
        //             </td>   
        //         </Fragment>
        //     )
    
        //     newRowDate.push(
        //         <Fragment>
        //             <td style={{backgroundColor: locationTypeData?.colour}}>
                        
        //             </td>
        //         </Fragment>
        //     );
            
        //         <Fragment>
        //             <tr>   
        //                 {rowHeader}
        //                 {newRowDate}
        //             </tr>
        //         </Fragment>
        // }
        
    }

    return (
        <div>
            <Modal show={error} modalClosed={() => setError(false)}>
                <h3>Oops, something went wrong!</h3>
                <hr />
                <p>{error}</p>
            </Modal>
            <Modal 
                large tall
                show={showCommentModal}
                modalClosed={()=>setShowCommentModal(false)}>
                <div className={classes.CommentModal}>
                    {commentContent}
                </div>
            </Modal>
            <Banner show={bannerText} bootstrapClass={bannerTextClass}>{bannerText}</Banner>  
            {locations}
        </div>
    );
}

export default WorkLocationsDashboard;