
import React, { Fragment, useEffect, useRef } from "react"
import { useState, useCallback } from "react";
// import MaterialTable, {MTableToolbar, TablePagination } from "material-table"
import MaterialTable from "material-table"
import tableIcons from "./MaterialIcons/MaterialIcons";
import axios from "axios";
import { AWS_API, cognitoClientId, cognitoUserpoolId } from "../../../utilities/globalVariables";
import classes from './Reporting.module.css'
import { dropDownColumns } from "../../../utilities/globalObjects";
// import { tableForeignKeys, dropDownColumns } from "../../../utilities/globalObjects";
// import InfiniteScroll from 'react-infinite-scroll-component';
// import { SaveAlt } from "@material-ui/icons";


function humanize(str) {
    var i, frags = str.split('_');
    for (i=0; i<frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }
  

const Reporting = (props) => {

    const [data, setData] = useState();
    const [columns, setColumnData] = useState();
    // const [getForeignKeys, setGetForeignKeys] = useState(false);
    // const [tableName, setTableName] = useState('resource_report')
    const tableName = 'resource_report';
    // const [firstLoad, setFirstLoad] = useState();
    // const [rowsPerPage, setRowsPerPage] = useState();
    const tableRef = useRef();


    
    const getMasterData = useCallback( async () => {
        console.log('ive been run')
        let res;
        // setLoading(true);
        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {table: tableName}
        };
        // if (getForeignKeys){
        //     config.params['keys'] = tableForeignKeys[tableName]
        // }
        // console.log('CONFIG', config)
        
        // call lambda function to get master data
        try {
            res = await axios.get(AWS_API + 'reporting/', config);
            setData(res.data);
            console.log('RESDATA', res.data)
        } catch (err) {
            // setError(handleAwsApiError(err, history) ?? 'Error encountered while fetching master data');
        }
        // let test = res.data.map(element => {
        //     console.log('here')
        //     return element.fistname;
        // })
        // console.log('TEST!',test);
        //tableData is added by material table when rendering and we do not want to include it in columns as it will throw an error
        let newColumns = Object.keys(res.data[0]).filter(key => {return key !== 'tableData'}).map(key => {
            let global = dropDownColumns
            console.log('global', global)
            let object = {field: key, title: humanize(key)}
            if (dropDownColumns[tableName][key]){
                let set = new Set();
                res.data.forEach(element => {
                    set.add(element[key])
                })

                console.log('set',set)
                let objectVals = {};
                Array.from(set).sort().forEach(element => {
                    objectVals[element] = element
                    console.log('element', element)
                })
                console.log("objectVals: ", objectVals);
                object['lookup'] = objectVals;
            }



            //if key has date in it then render data as a date
            // if (key.search('date') !== -1){
            //     object['type'] = 'date'
            // }
            console.log(object)
            return object
        })
        let objectVals = {};

        const monthYearIndex = newColumns.findIndex(q => q.field === "month_year");

        // const sorted = Object.entries(newColumns[monthYearIndex].lookup)
        // .sort((a, b) => {
        //     const [monthA, yearA] = a[1].split('/');
        //     const [monthB, yearB] = b[1].split('/');
        //     return yearA.localeCompare(yearB) || monthA.localeCompare(monthB);
        // })
        // .reduce((acc, [key, value]) => {
        //     acc[key] = value;
        //     return acc;
        // }, {});
        const sorted = Object.fromEntries(
            Object.entries(newColumns[monthYearIndex].lookup)
              .sort((a, b) => {
                const [monthA, yearA] = a[0].split('/');
                const [monthB, yearB] = b[0].split('/');
                return yearA.localeCompare(yearB) || monthA.localeCompare(monthB);
              })
          );
        console.log("Sorted: ", Object.entries(sorted).map(k=>({[k[0]]:k[1]})));
        for (const [key] of Object.entries(sorted)){
            // console.log(`${key}: ${value}`);
            objectVals[key] = key;
        }
        // console.log("Sorted array: ", Array.from(sorted));
        // Array.from(sorted).forEach(element => {
        //     objectVals[element] = element
        //     console.log('element', element)
        // })
        console.log("objectVals: ", objectVals);
        // newColumns[monthYearIndex].lookup = objectVals
        // console.log('Columns',newColumns)
        newColumns[monthYearIndex].lookup = objectVals;
        setColumnData(newColumns)
        
        // setLoading(false);
    }, [setData, setColumnData, props.token]);
    
    useEffect(() => {
        getMasterData();
    }, [getMasterData])
    console.log('tableRef',tableRef);

    const exportCSV = useCallback(async () => {
        console.log('tableRef', tableRef)
        let res;
        const filterValues = tableRef?.current?.state?.columns.filter(element => element?.tableData?.filterValue !== undefined && element?.tableData?.filterValue !== null && element?.tableData?.filterValue !== '' && !element.hiddenByColumnsButtonn && element.tableData.filterValue.length !== 0 )
        .map( element => {console.log(element,'ele');return {[element.field]: element.tableData.filterValue}})
        console.log('filterVals', filterValues)
        console.log(tableRef?.current)
        // let columnsToFetch = tableRef?.current?.state?.columns.map(element => {console.log('element', element);return 'hello'})
        let columnsToFetch = tableRef?.current?.state?.columns.filter(element => !element.hiddenByColumnsButton)
        .map(element => {return element.field})
        console.log('Columns to fetch', columnsToFetch)

        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {
                table: tableName,
                'filter': JSON.stringify(filterValues),
                columns: JSON.stringify(columnsToFetch)
            }
        };
        console.log('CONFIG', config)
        
        // call lambda function to get master data
        try {
            res = await axios.get(AWS_API + 'reporting/export', config);
            // console.log(res.data)
            console.log('RESDATA', res.data)
        } catch (err) {
            // setError(handleAwsApiError(err, history) ?? 'Error encountered while fetching master data');
        }
        let blob = new Blob([res.data])
        let link=document.createElement('a');
        link.href=window.URL.createObjectURL(blob);
        link.download=`${tableName}.csv`;
        document.body.appendChild(link)
        link.click();
        link.parentNode.removeChild(link);
    }, [props.token]);
    return (<Fragment>
        <button className={["btn btn-success", classes.Button].join(' ')} onClick={exportCSV}>Export CSV</button>
            <MaterialTable
            tableRef={tableRef}
            title={tableName}
            icons={tableIcons}
            columns={columns}
            data={data}
            options={{
                filtering:true,
                sorting:true,
                paging: true,
                columnsButton: true,
                
            }}
            // data={query =>
            //     new Promise((resolve, reject) => {
            //     let res;
            //     //filter and map current filters to send off to backend
            //     const filterValues = tableRef?.current?.state?.columns.filter(element => element?.tableData?.filterValue !== undefined && element?.tableData?.filterValue !== null && element?.tableData?.filterValue !== '' && !element.hiddenByColumnsButtonn && element.tableData.filterValue.length !== 0 )
            //     .map( element => {console.log(element,'ele');return {[element.field]: element.tableData.filterValue}})

            //     const config = {
            //         headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            //         params: {
            //             table: tableName,
            //             'filter': JSON.stringify(filterValues),
            //             'per_page': query.pageSize,
            //             'page': query.page +1
            //             }
            //     };
            //     if (getForeignKeys){
            //         config.params['keys'] = tableForeignKeys[tableName]
            //     }


                

            //     res = axios.get(AWS_API + 'reporting/', config).then();
            //     setData(res.data);
            //     console.log('RESDATA', res.data)

            //     console.log('filtered',filterValues)

            //     // url += '&columns=' + '*'

            //     // fetch(url)
            //     // .then(response => response.json())
            //     // .then(result => {
            //     //     resolve({
            //     //     data: result.data,
            //     //     page: result.page - 1,
            //     //     totalCount: result.total,
            //     //     })
            //     // })
            //     // })
            //   })}
            // components={{
            //     Pagination: props => (
            //                  <TablePagination
            //                  {...props}
            //                 rowsPerPageOptions={[5, 10, 20, 30]}
            //             rowsPerPage={this.state.numberRowPerPage}
            //             count={this.state.totalRow}
            //             page={
            //               firstLoad
            //                 ? this.state.pageNumber
            //                 : this.state.pageNumber - 1
            //             }
            //             onChangePage={(e, page) =>
            //               this.handleChangePage(page + 1)
            //             }
            //             onChangeRowsPerPage={event => {
            //               props.onChangeRowsPerPage(event);
            //               this.handleChangeRowPerPage(event.target.value);
            //             }}
            //           />
            //         ),
            //               }}
            editable={{
                onBulkUpdate: changes => 
                    new Promise((resolve, reject) => {
                        console.log('string',JSON.stringify(tableRef))
                        // let postData = {table: viewTableName, changes: changes};
                        // console.log('old',data);
                        // console.log('new',data)

                        // const headers = {headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}};
                        // const res = axios.post(AWS_API + 'reporting/update', {postData: postData}, headers);
                        // resolve(res)
                        
                    }).then((res) => {console.log('res', res)})
            }}
            />
        </Fragment>);


}

export default Reporting;