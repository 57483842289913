import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  AWS_API,
  cognitoClientId,
  cognitoUserpoolId,
} from "../../utilities/globalVariables";
import classes from "./TimeSheet.module.css";
import { handleAwsApiError } from "../../utilities/functions";
// import Table from "./Table";
// import { ToggleButton } from "react-bootstrap";
import DailyTSView from "./DailyTSView/DailyTSView";
import WeeklyTSViews from "./WeeklyTSView/WeeklyTSView";
import Modal from "../../UI/Modal/Modal";
import SpinnerDark from "../../UI/SpinnerDark/SpinnerDark";
import Banner from "../../UI/Banner/Banner";
// import TimeSheetTitle from "./TimeSheetTitle";

import { handleBanner } from "../../utilities/functions";

const TimeSheet = (props) => {
  //Data states
  // const [prevMonday, setPrevMonday] = useState(new Date())
  const [date, setDate] = useState(new Date());
  const [timeSheetData, setTimeSheetData] = useState();
  const [bannerText, setBannerText] = useState();
  const [bannerTextClass, setBannerTextClass] = useState()

  // UI state
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  //set back to daily when weekly is complete
  const [viewMode, setViewMode] = useState();

  // react router history instance to navigate user to other pages
  let history = useHistory();

  useEffect(() => {
    // let prevMonday = new Date();
    // prevMonday.setDate(prevMonday.getDate() - ((prevMonday.getDay() + 6) % 7));
    // setPrevMonday(prevMonday);
    setDate(new Date().toLocaleDateString("sv-SE", {timeZone: 'Australia/Sydney'}).trim(0,10));
  }, [setDate]);
  console.log("Initial date", date)
  const fetchData = useCallback( async () => {
      const config = {
        headers: {
          authorization: props.token,
          appclientid: cognitoClientId,
          userpoolid: cognitoUserpoolId,
          email: props.email,
        },
      };
      console.log("Config: ", config);
      try {
        const res = await axios.get(
          AWS_API + "resource-data/time-entry",
          config
        );
        console.log("res.data", res.data)
        setTimeSheetData(res.data);
      } catch (err) {
        setError(
          handleAwsApiError(err, history) ??
            "Error encountered while fetching master data"
        );
        console.log("Error");
        console.log(err);
      }
  }, [setTimeSheetData, history, props.email, props.token])

  useEffect(() => {
    console.log('width',window.innerWidth)
    setLoading(true)

    // only call fetch data function once props.token has been received
    if (props.token) {
        fetchData();
    }
    setLoading(false)

    window.innerWidth < 550 ? setViewMode('daily') : setViewMode('weekly') 
    
  }, [props.token, setLoading, fetchData]);

  const updateData = async (newData) =>{
    console.log('NEWDATA', newData)
    //check if newData contains empty projectID or ActivityID, if yes do not proceed
    let activities = Object.values(newData).filter(entry => entry.activity_id === '' || entry.project_id === '');
    if (activities.length >0){
      handleBanner(setBannerText, 'Please ensure a Project and Activity have been selected', "alert alert-danger", setBannerTextClass);
      return;
    }
    setLoading(true);
    console.log("update data function", newData);
    console.log(newData)
    const config = {
      headers: {
        authorization: props.token,
        appclientid: cognitoClientId,
        userpoolid: cognitoUserpoolId,
        email: props.email,
      },
    };
    try{
      const res = await axios.post(AWS_API + 'resource-data/time-entry', newData, config);
      const data = res.data;
      console.log('write-timesheet data response: ', data);
      handleBanner(setBannerText, 'Successfully Updated Records', 'alert alert-success', setBannerTextClass)
      fetchData();

    } catch (err){
      console.log("postError", err)
      setError(
        handleAwsApiError(err, history) ??
          "Error encountered while updating data"
      );
    }

    setLoading(false)
    
  }
  console.log('error',error)

  //condition to determine what view is rendered, default is daily
  let content = <SpinnerDark />;
  if (!loading){
    
    if (viewMode === "daily") {
      content = <DailyTSView date={date} data={timeSheetData}  setDate={setDate} updateData={updateData}/>;
    } else if (viewMode === "weekly") {
      content = <WeeklyTSViews date={date} setDate={setDate} data={timeSheetData} updateData={updateData}/>;
    }
  };
  return (
    <div className={classes.Timesheet}>
      <div
        style={{
          display: "flex",
          flexFlow: "row nowrap",
          alignItems: "stretch",
          justifyContent: "flex-end",
        }}
      >
        <button className={["btn btn-secondary", classes.DateViewButton].join(' ')} onClick={() => setViewMode("daily")}>Daily</button>
        <button className={["btn btn-secondary", classes.DateViewButton].join(' ')} onClick={() => setViewMode("weekly")}>Weekly</button>
      </div>
      {content}
      <Banner show={bannerText} bootstrapClass={bannerTextClass}>{bannerText}</Banner>  
      <Modal show={error} modalClosed={() => setError(false)}>
        <h3>Oops, something went wrong!</h3>
        <hr />
        <p>{error}</p>
      </Modal>
    </div>
  );
};

export default TimeSheet;
