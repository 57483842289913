import React from 'react';

import classes from './Links.module.css';

import powerBI from '../../assets/images/powerBI.png';
import googleSheets from '../../assets/images/googleSheets.png';
import timesheet from '../../assets/images/timesheet.png';
import leave from '../../assets/images/leave.png';
import workLocations from '../../assets/images/workLocations.png';


const Links = (props) => {

    let linkList = [
        {
        url: "http://oshc-dashboard-test.s3-website-ap-southeast-2.amazonaws.com/",
        title: "OSHC+ Monitoring - PRE-PROD",
        width: '50%',
        img: powerBI
        },
        {
        url: "https://docs.google.com/spreadsheets/d/1Idp5P-HynNeSQhXhc73u8Qcqv_K7nxynoLtNWcksd-U/edit#gid=0",
        title: "NRL/Super Rugby Last Man Standing",
        width: '35%',
        img: googleSheets
        },
        {
        url: "https://docs.google.com/spreadsheets/d/1pHJh10L3noX4U5P7dD-kqCLOGC1b_TTyGs3lfEjD0fo/edit?usp=gmail#gid=0",
        title: "Work Locations",
        width: '100%',
        img: workLocations
        },
        
    ];

    if (props.role === "SuperUser"){
        linkList.push({
            url: "https://ap-southeast-2.quicksight.aws.amazon.com/sn/dashboards/8e34ab88-67ad-4b8b-b73a-4d58cb863fde?#",
            title: "Timesheet Report",
            width: '50%',
            img: timesheet
        });
        linkList.push({
            url: "https://ap-southeast-2.quicksight.aws.amazon.com/sn/dashboards/f22a43fa-f004-41f2-9977-bd4b047a3459?#",
            title: "Leave Report",
            width: '50%',
            img: leave
        });
    }
    

    const Link = (props) => {
        return (
            <a href={props.url}>
                <div className={classes.Link} >
                    <br/>
                    <img alt="logo" src={props.img} width={props.width}/>
                    <h6 className={classes.LinkTitle}>{props.title}</h6>
                </div>
            </a>
        );
    }

    const links = linkList.map(obj => <Link {...obj}/>)
    return (
    <div className={classes.Links}>
        <div style={{width: '100%'}}>
            <h2>Internal Citra Links</h2>
            <hr />
        </div>
        {links}
    </div>

    );

}

export default Links;