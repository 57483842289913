import React from 'react';

import classes from './SideDrawer.module.css';
import Logo from '../../Logo/Logo';
import NavigationItems from '../NavigationItems/NavigationItems';
import Backdrop from '../../../UI/Backdrop/Backdrop';
import Aux from '../../../hoc/Auxiliary/Auxiliary';
import ShareButton from '../../../assets/images/share-button.png';


const SideDrawer = (props) => {
    let attachedClasses = [classes.SideDrawer, classes.Close];
    if (props.open) {
        attachedClasses = [classes.SideDrawer, classes.Open];
    }

    let appInstallPrompt = null;
    if (props.showInstallPrompt) {
        appInstallPrompt = (
            <Aux>
                <hr />
                <div className={classes.InstallApp}>
                <b>Install The App </b>
                    <div style={{paddingTop: "15px"}}>
                        <button className={[classes.InstallButton, classes.InstallDismiss].join(' ')} onClick={props.dismissed}>Not now</button>
                        <div className={classes.Divider}/>
                        <button className={classes.InstallButton} onClick={props.accepted}>Install</button>
                    </div>
                </div>

            </Aux>
        );
    }

    let appInstallInstructions = null;
    if (props.showInstallInstruct && !props.showInstallPrompt) {
        appInstallInstructions = (
            <Aux>
                <hr />
                <div className={classes.InstallApp}>
                Install this app on your device: Tap &nbsp;<img src={ShareButton} width="10px" alt="Share-button"/> &nbsp;at the bottom of your screen and then "Add to homescreen"
                </div>

            </Aux>            
        );
    }    

    return (
        <Aux>
            <Backdrop show={props.open} clicked={props.closed}/>
            <div className={attachedClasses.join(' ')} onClick={props.closed}>
                <div className={classes.Logo}>
                    <Logo />
                </div>
                <nav>
                    <NavigationItems signout={props.signout} role={props.role} email={props.email}/>
                </nav>

                {appInstallPrompt}
                {appInstallInstructions}                
            </div>
        </Aux>
    );
};

export default SideDrawer;