import {React, useState} from "react";
import classes from './Layout.module.css';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import Toolbar from "../../UI/Navigation/Toolbar/Toolbar";
import SideDrawer from "../../UI/Navigation/SideDrawer/SideDrawer";


export default function Layout(props) {

  const [showSideDrawer, setShowSideDrawer] = useState(false);

  const sideDrawerClosedHandler = () => {
    setShowSideDrawer(false);
  }

  const sideDrawerToggleHandler = () => {
    setShowSideDrawer(!showSideDrawer);
  }

  return (
    <Aux >
      <Toolbar 
        role={props.role}
        email={props.email}
        drawerToggleClicked={sideDrawerToggleHandler}
        signout={props.signout}

      />
      <SideDrawer 
        closed={sideDrawerClosedHandler} 
        open={showSideDrawer}
        showInstallPrompt={props.showInstallPrompt}
        showInstallInstruct={props.showInstallInstruct}
        accepted={props.accepted}
        dismissed={props.dismissed}        
        role={props.role}
        email={props.email}
        signout={props.signout}
        />
      <main className={classes.Body}>
        {props.children}
      </main>
    </Aux>
  )
}