export const masterDataPrimaryKeys = {
    resources: ['email'],
    projects: ['project_desc', 'customer_id'],
    customers: ['business_name', 'address'],
    bookings: ['date', 'resource_id', 'project_id', 'activity_id'],
    activities: ['project_id', 'activity']

};

export const masterDataSortKeys = {
    roll: 'date',
    casual_bookings: 'date',
}

export const updateTableByFormType = {
    updateBookingSchedule: {
        table: 'permanent_bookings',
        updateCols: ['mon_1', 'tue_1', 'wed_1', 'thu_1', 'fri_1', 'mon_2', 'tue_2', 'wed_2', 'thu_2', 'fri_2'],
        updateValueName: {
            fortnightly: ['mon1', 'tue1', 'wed1', 'thu1', 'fri1', 'mon2', 'tue2', 'wed2', 'thu2', 'fri2'], 
            weekly: ['mon1', 'tue1', 'wed1', 'thu1', 'fri1', 'mon1', 'tue1', 'wed1', 'thu1', 'fri1']
            }
    }
}

export const autoApprovedForms = ['permissionSlip'];
//object for lookup field in columns on materialTable
export const tableForeignKeys = {
    bookings: {
        resource_id: {foreign_table: 'resources', infoNeeded: 'email' },
        project_id: {foreign_table: 'projects', infoNeeded: 'project_desc'},
        activity_id: {foreign_table: 'activities', infoNeeded: 'activity'}
    }
}
export const dropDownColumns = {
    resource_report: {
        date: true,
        resource_id: true,
        fistname: true,
        surname: true,
        email: true,
        project_id: true,
        project_desc: true,
        activity: true,
        week_start_date: true,
        day_of_week: true,
        month_year: true,
        status: true
    }
};

export const statusMap = {
    'Open': 10,
    'Submitted': 20,
    'Approved': 30,
    'Invoiced': 40

}

export const bootstrapTableDateFields = ['date', 'week_start_date', 'valid_from', 'valid_to', 'start_date', 'end_date'];
export const bootstrapTableTimeFields = [];
export const bootstrapTableDateTimeFields = ['created_at', 'status_changed_at'];
export const workLocationTypes = ['Office', 'Office Required', 'Home', 'Other', 'Leave / Not Working'];

export const requestTypes = [
    {label: 'Leave', value: 'leave'}, 
    {label: 'Sick', value: 'sick'}, 
    {label: 'Other', value: 'other'}
];



export const contractTypes = ['Permanent', 'Contract', 'Director', 'Casual'];
export const ccTypes = ['cost centre 1', 'cost centre 0', 'cost centre 2', 'c01', 'C02', 'NSW Contract', 'NSW'];

export const masterDataButtons = [
    {table: 'bookings', label: 'Bookings'},
    {table: 'resources', label: 'Resources'},
    {table: 'projects', label: 'Projects'},
    {table: 'customers', label: 'Customers'},
    {table: 'activities', label: 'Activities'},
    {table: 'requests', label: 'Requests'},
    {table: 'request_types', label: 'Request Types'},
    {table: 'request_subtypes', label: 'Request Sub-Types'},
    {table: 'resource_approvers', label: 'Resource Approvers'},
    {table: 'work_location_types', label: 'Work Location Types'}
];

export const bootstrapTableConfig = {
    bookings : {
        uniqueSelectId: 'booking_id'
    },
    resources : {
        uniqueSelectId: 'resource_id',
        primaryKeys: ['resource_id'],
        filterColumns: [{columns: ['contract_def'], filterType: 'select'},{columns: ['email'], filterType: 'text'}],
        editableFields: [
            {columns: ['contract_def', 'cost_centre', 'valid_from', 'valid_to', 'email'], filterType: 'TEXT'}
        ],
        insertRow: [
            {columns: ['contract_def'], dataType: 'react-select', options: {source: 'globalObjectsArray', array: contractTypes}},
            {columns: ['cost_centre'], dataType: 'react-select', options: {source: 'globalObjectsArray', array: ccTypes}},
            {columns: ['valid_from'], dataType: 'date'},
            {columns: ['valid_to'], dataType: 'date'},
            {columns: ['email'], dataType: 'text'}
        ]
    },
    projects : {
        uniqueSelectId: 'project_id',
        primaryKeys: ['project_id'],
        filterColumns: [{columns: ['project_desc'], filterType: 'text'}],
        editableFields: [{columns: ['project_desc'], filterType: 'TEXT'}],
        insertRow: [
            {columns: ['project_desc'], dataType: 'text'},
            {columns: ['customer_id'], dataType: 'react-select', options: {source: 'database', name: 'customers', value: 'customer_id', labels: ['customer_id', 'business_name'], sort: true}},
            {columns: ['bill_to_customer_id'], dataType: 'react-select', options: {source: 'database', name: 'customers', value: 'customer_id', labels: ['customer_id', 'business_name'], sort: true}},
            {columns: ['valid_from'], dataType: 'date'},
            {columns: ['valid_to'], dataType: 'date'}
        ]
    },
    customers : {
        uniqueSelectId: 'customer_id',
        primaryKeys: ['customer_id'],
        filterColumns: [{columns: ['business_name'], filterType: 'text'}],
        editableFields: [{columns: ['business_name', 'address', 'contact'], filterType: 'TEXT'}],
        insertRow: [
            {columns: ['business_name'], dataType: 'text'},
            {columns: ['address'], dataType: 'text'},
            {columns: ['contact'], dataType: 'text'}
        ]
    },
    activities : {
        uniqueSelectId: 'activity_id',
        primaryKeys: ['activity_id'],
        filterColumns: [{columns: ['activity'], filterType: 'text'}],
        editableFields: [{columns: ['activity'], filterType: 'TEXT'}],
        insertRow : [
            {columns: ['project_id'], dataType: 'react-select', options: {source: 'database', name: 'projects', value: 'project_id', labels: ['project_id', 'project_desc'], sort: true}},
            {columns: ['activity'], dataType: 'text'}
        ]
    },
    requests : {
        uniqueSelectId: 'request_id',
        primaryKeys: ['request_id'],
        filterColumns: [{columns: ['request_type', 'current_status'], filterType: 'select'}],
        excludeColumns: ['hash', 'hash_used']
    },
    request_types : {
        uniqueSelectId: 'id',
        primaryKeys: ['id'],
        editableFields: [{columns: ['request_type'], filterType: 'TEXT'}],
        insertRow : [
            {columns: ['request_type'], dataType: 'text'}
        ]
    },
    request_subtypes : {
        uniqueSelectId: 'id',
        primaryKeys: ['id'],
        editableFields: [{columns: ['request_subtype'], filterType: 'TEXT'}],
        insertRow : [
            {columns: ['request_type_id'], dataType: 'react-select', options: {source: 'database', name: 'request_types', value: 'id', labels: ['request_type'], sort: true}},
            {columns: ['request_subtype'], dataType: 'text'}
        ]
    },
    resource_approvers : {
        uniqueSelectId: 'id',
        primaryKeys: ['id'],
        editableFields: [{columns: ['comment'], filterType: 'TEXT'}],
        insertRow : [
            {columns: ['resource_id'], dataType: 'react-select', options: {source: 'database', name: 'resources', value: 'resource_id', labels: ['resource_id', 'email'], sort: true}},
            {columns: ['approving_resource_id'], dataType: 'react-select', options: {source: 'database', name: 'resources', value: 'resource_id', labels: ['resource_id', 'email'], sort: true}},
            {columns: ['comment'], dataType: 'text'}
        ],
        addEmail: {column: ['created_by']},

        view: 'view_resource_approvers'
    },
    view_request_with_approvers : {
        uniqueSelectId: 'request_id',
        primaryKeys: ['request_id'],
        editableFields: [{columns: ['approver_comment'], filterType: 'TEXT'}],
        filterColumns: [
            {columns: ['first_name', 'last_name', 'comment'], filterType: 'text'},
            {columns: ['request_type', 'request_subtype', 'current_status', 'status_changed_by'], filterType: 'select'}
        ],
        excludeColumns: ['request_id', 'request_type_id', 'request_subtype_id', 'project_id', 'project_desc', 'resource_id', 'approving_resource_id', 'line_manager_id']
    },
    work_location_types : {
        uniqueSelectId: 'location_id',
        primaryKeys: ['location_id'],
        insertRow : [
            {columns: ['location_type'], dataType: 'text'},
            {columns: ['colour'], dataType: 'colour-picker'}
        ]
    }
}