// Steps to deploy this app using aws cli:
// 1, Configure an aws profile named citra-dev
// 2, Go to root director on this folder on terminal
// 3. npm run build
// 4, aws --profile citra-dev s3 sync build/ s3://app.citras.io

export const AWS_API = 'https://7hqyirz611.execute-api.ap-southeast-2.amazonaws.com/'

// cognito variables for dashboard user pool 
export const cognitoRedirectURLDashboard = 'https://app.citras.io';
// export const cognitoRedirectURLDashboard = 'http://localhost:3000';
export const cognitoTokenAPIDashboard = 'https://citrapp-dev.auth.ap-southeast-2.amazoncognito.com/oauth2/token';
export const cognitoClientIdDashboard = '3k0boqpqb7nfq3eslp3eii0jib';
export const cognitoUserpoolIdDashboard = 'ap-southeast-2_OL6RPoHwJ';
export const cognitoURLDashboard = 'https://citrapp-dev.auth.ap-southeast-2.amazoncognito.com/login?client_id=3k0boqpqb7nfq3eslp3eii0jib&response_type=code&scope=email+openid+phone&redirect_uri=' + cognitoRedirectURLDashboard;




// cognito variables for live version on S3 
export const cognitoRedirectURL = 'https://app.citras.io';
// export const cognitoRedirectURL = 'http://localhost:3000';
export const cognitoTokenAPI = 'https://citrapp-live.auth.ap-southeast-2.amazoncognito.com/oauth2/token';
export const cognitoClientId = '1nou5loi07o7qb8l0uom245257';
export const cognitoUserpoolId = 'ap-southeast-2_9fioKZ0OH';
export const cognitoURL = 'https://citrapp-live.auth.ap-southeast-2.amazoncognito.com/login?client_id=1nou5loi07o7qb8l0uom245257&response_type=code&scope=email+openid+phone&redirect_uri=' + cognitoRedirectURL;

// generic variables for both localhost and dev/prod
export const viewTableName = 'resource_report_summary';
export const cognitoGrantTypeAuth = 'authorization_code';
export const cognitoGrantTypeRefresh = 'refresh_token';
export const timezone = 'Australia/Sydney';

// legacy activeOOSH vars
export const formioAPI = 'https://rgpmmogmwzdplxi.form.io/';
export const bscCutOffHour = 0;
export const ascCutOffHour = 12;
export const vacCutOffHour = 0;
export const bscStartTime = 6.5;
export const ascStartTime = 15;
export const vacStartTime = 9;
export const centreLinkMon1 = new Date('2021-11-1');
export const bscSessionLength = 2.5;
export const ascSessionLength = 3;
export const vacSessionLength = 8;