import { useState, useEffect, Fragment, useCallback } from 'react';
import { useHistory, useLocation } from "react-router-dom";

import classes from './ApprovalsCentre.module.css';

import axios from 'axios';
import {
    AWS_API,
    cognitoClientId,
    cognitoUserpoolId,
} from "../../utilities/globalVariables";
import { handleAwsApiError,
    //  handleBanner 
    handleBannerText
} from "../../utilities/functions";
import BSTable from "../../UI/BSTable/BSTable";
import SpinnerDark from "../../UI/SpinnerDark/SpinnerDark";
import Modal from '../../UI/Modal/Modal';

// icons
import {AiOutlineStop} from "react-icons/ai";
import {TiTickOutline} from "react-icons/ti";
import { bootstrapTableConfig } from '../../utilities/globalObjects';
import Banner from '../../UI/Banner/Banner';
import BackdropSpinner from '../../UI/BackdropSpinner/BackdropSpinner';


const numsToDay = {
    0: 'Sun',
    1: 'Mon',
    2: 'Tues',
    3: 'Wed',
    4: 'Thurs',
    5: 'Fri',
    6: 'Sat'
}

const ApprovalsCentre = (props) => {

    // data states
    const [data, setData] = useState();
    // const [newEntryData, setNewEntryData] = useState();
    const [error, setError] = useState(false);
    const [selected, setSelected] = useState([]);

    // UI states
    const [loadingApprovals, setLoadingApprovals] = useState(true);
    const [loadingCellUpdate, setLoadingCellUpdate] = useState(false);
    console.log("Loading Cell Update: ", loadingCellUpdate)
    const [bannerText, setBannerText] = useState("");
    
    // react router history instance to navigate user to other pages
    let history = useHistory();
    const location = useLocation();

    Date.prototype.addDays = function(days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }

    const getApprovals = useCallback(async() => {

        setLoadingApprovals(true);

        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };
        
        try{
            // get approvals
            const res = await axios.get(AWS_API + 'master-data/fetch-approvals', config);
            console.log("Res Data: ", res.data);
            setData(res.data)
        } catch (err) {
            setError(handleAwsApiError(err, history) ??"Error encountered while fetching requests");
            setLoadingApprovals(false)
            console.log("Error: ", err);
        }

        setLoadingApprovals(false)

    }, [history, props.token]);

    // const updateTimeSheets = useCallback(async() => {


    // });
    
    const getDates = (startDate, stopDate) => {
        // setLoading(true);
        var dateArray = []
        var date = new Date(startDate);
        stopDate = new Date(stopDate);
        //added an extra day and sent while loops to less than
        while (date.toLocaleDateString("sv-SE", {timeZone: 'Australia/Sydney'}) <= stopDate.toLocaleDateString("sv-SE", {timeZone: 'Australia/Sydney'})) {
            dateArray.push(
                [date.toLocaleDateString("sv-SE", {timeZone: 'Australia/Sydney'}), numsToDay[date.getDay()]]);
            date = date.addDays(1);
        }
        // setLoading(false);
        return dateArray;
    };

    const getTimeSheetDatesToUpdate =  (startDate, endDate) => {
        const datesArray = getDates(startDate, endDate);
        const datesArrayFiltered = datesArray.filter((date)=>{
            if(!['Sat','Sun'].includes(date[1])){
                return date;
            }
        });
        return datesArrayFiltered;
    };

    const getWeekStartDate = (date) => {
        const currentDate = new Date(date);
        const diffFromMonday = currentDate.getDay()-1;
        const startDateOfTheWeek = date.setDate(date.getDate()-diffFromMonday);
        return startDateOfTheWeek.toISOString();
    }

    const getApprovalTimeSheetEntries = (approval) => {
        const approvalTimeSheetEntries = [];
        if(['Annual','Sick'].includes(approval.request_subtype)){
            approval.timeSheetDates.forEach((date)=>{
                const resourceId = approval.resource_id;
                const projectId = 12; // Leave
                const activityId = (approval.request_subtype==='Annual') ? 13 : 12; // Annual or Sick Leave
                const status = 20; // Submitted
                approvalTimeSheetEntries.push(
                    {
                        "date": date[0],
                        "resource_id": resourceId,
                        "activity_id": activityId,
                        "project_id": projectId,
                        "forecasted": false,
                        "actual_hours": 8,
                        "status": status
                    }
                );
            });

        }

        return approvalTimeSheetEntries;
    }

    const getTotalTimeSheetEntries = (approvals) => {
        let totalTimeSheetEntries = [];
        approvals.forEach((approval)=>{
            const approvalTimeSheetEntries = getApprovalTimeSheetEntries(approval);
            totalTimeSheetEntries = totalTimeSheetEntries.concat(approvalTimeSheetEntries);
        });
        return totalTimeSheetEntries;
    }


    const sendTimeSheetEntries = async(timeSheetEntries) => {
        console.log("time sheet entries: ", timeSheetEntries);
        const config = {
            headers: {
              authorization: props.token,
              appclientid: cognitoClientId,
              userpoolid: cognitoUserpoolId,
              email: props.email,
            },
          };
          try{
            const res = await axios.post(AWS_API + 'resource-data/time-entry', timeSheetEntries, config);
            const data = res.data;
            console.log('write-timesheet data response: ', data);
          } catch (err){
            console.log("postError", err)
            setError(
              handleAwsApiError(err, history) ??
                "Error encountered while updating data"
            );
          }
    };

    const updateTimeSheets = async(approvals) => {
        approvals.forEach((approval)=>{
            approval['timeSheetDates'] = (getTimeSheetDatesToUpdate(approval.start_date, approval.end_date));
        });
        const totalTimeSheetEntries =  getTotalTimeSheetEntries(approvals);
        totalTimeSheetEntries.length > 0 && await sendTimeSheetEntries(totalTimeSheetEntries);
    };

    const getDataApproved = (data, postData) => {
         const dataApproved =  data.filter((d) => {
            let check = false;
            postData.updateObj.forEach((p) => {
                if (p.request_id === d.request_id && p.current_status === "approved") {
                    check = true
                };
            })
            if (check === true) {
                return d
            };
        });

        return (dataApproved) ? dataApproved : [];
    }

    //This is the approval function where your functionality will come into place
    const updateApprovalStatus = async(selectedIds, status) => {
        // const updateApprovalObj = selectedIds.map(id => {id: id, status: status});
        let updateApprovalObj = [];
        selectedIds.forEach(id => {
            updateApprovalObj.push({request_id: id, current_status: status});
        })
        console.log("Update Approval Obj: ", updateApprovalObj);

        const headers = {headers :{authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}};

        const postData = {
          updateObj: updateApprovalObj,
        };

        console.log("Post Data: ", postData)

        try {
            const res = await axios.post(AWS_API + 'master-data/update-request-status', {postData : postData}, headers);
            console.log('res: ', res.data);
            getApprovals();
            const dataApproved = getDataApproved(data, postData);
            console.log("dataApproved: ", dataApproved);
            if(dataApproved.length>0){
                await updateTimeSheets(dataApproved);
            };
            handleBannerText(setBannerText, `Data Updated Successfully`);

        } catch (err) {
            setError(handleAwsApiError(err, history) ??"Error encountered while fetching master data");
            console.log("Error: ", err);
        }
    };

    useEffect(() => {
        getApprovals();
    }, [getApprovals]);

    const updateData = async(oldValue, newValue, row, column) => {
        setLoadingCellUpdate(true);

        // get primary keys
        const primaryKeys = bootstrapTableConfig['view_request_with_approvers']?.primaryKeys;
        if (!primaryKeys){
          setError('Primary keys not found for selected table: view_request_with_approvers');
          setLoadingCellUpdate(false);
          return false;
        }
    
        // construct primary key object
        const primaryKeysObj = {};
        primaryKeys.forEach(key => primaryKeysObj[key] = row[key])
    
        const headers = {headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}};
    
        const updateCol = column.dataField;
        const postData = {
          table: 'requests',
          updateObj: {[updateCol]: newValue},
          primaryKeysObj: primaryKeysObj
        };
        console.log('posting: ', postData);
    
        //call lambda function
    
        try {
          const res = await axios.post(AWS_API + 'master-data/update-data', {postData: postData}, headers);
          console.log('res: ', res.data);
          handleBannerText(setBannerText, `Data Updated Successfully`);

        } catch (err) {
          setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while updating settings');
          console.log('error whilst recording roll event: ', err);
        }
    
        setLoadingCellUpdate(false);
    }

    let table = <SpinnerDark/>;
    if (!loadingApprovals){
        console.log("Selected: ", selected);
        table = (
            <BSTable
                selected={selected}
                setSelected={setSelected}
                table="view_request_with_approvers"
                data={data}
                enableEdit
                updateFunction={updateData}
            />
        )
    }
    let backdropSpinner;
    if (loadingCellUpdate) {
      backdropSpinner = <BackdropSpinner spinner='small-light'/>;
    }

    const buttonSize = '2rem';
    const fontSize = '11px';

    return (
        
        <div>
            <Banner show={bannerText}>
                {bannerText}
            </Banner>
            <Modal show={error} modalClosed={() => setError(false)}>
                <h3>Oops, something went wrong!</h3>
                <hr />
                <p>{error}</p>
            </Modal>
            {backdropSpinner}
            <Fragment>
                <div>
                    <h3>Approvals Centre</h3>
                    <hr/> 
                    <Fragment>
                        <div className={classes.HeaderButtonRow}>
                            <button className="btn btn-danger btn-sm" onClick={() => updateApprovalStatus(selected, 'rejected')} style={{fontSize: fontSize}}>Reject  <AiOutlineStop size={buttonSize} color='white'/></button>
                            <button className="btn btn-success btn-sm" onClick={() => updateApprovalStatus(selected, 'approved')} style={{fontSize: fontSize}}>Approve  <TiTickOutline size={buttonSize} color='white'/></button>
                        </div>
                    </Fragment>
                    {table}
                </div>
            </Fragment>
        </div>
    );
}

export default ApprovalsCentre;